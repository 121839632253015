﻿@import (reference) '../_Variables.less';

.filterblocks {
    padding-top: 35px;
    padding-bottom: 100px;

    .filterblock {
        background-color: @color-white;
        border-radius: 10px;
        text-align: center;
        padding: 50px 0px;
        display: block;
        color: @color-darkgrey;
        transition: top .5s, box-shadow .5s;
        position: relative;
        margin-bottom: 30px;
        top: 0px;

        img {
            width: 75px;
        }

        &:hover {
            top: -8px;
            text-decoration: none;
            box-shadow: 0 10px 10px 0px rgba(0,0,0,0.05);
        }
    }

    .filterblock-text {
        padding-top: 20px;
    }

    .allcongresstypes {
        position: absolute;
        text-align: center;
        margin-top: 10px;
        width: 100%;
        text-align: center;
    }
}