﻿@import (reference) '../_Variables.less';

.cookie-info {
    position: fixed;
    bottom: 0px;
    background-color: @color-white;
    font-size: inherit;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.3);
    width: 100%;
    z-index: 1000;

    .container a {
        text-decoration: underline;
    }

    .container a.cookie-more-info {
        color: @color-darkgrey;
        display: block;
        text-align: center;
    }

    @media(min-width:@breakpoint-xl) {
        .cookie-actions {
            padding-left: 50px;
        }
    }
}