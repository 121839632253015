﻿@import (reference) '../_Variables.less';

.aligned-row {
    display: flex;
    flex-flow: row wrap;

    &::before {
        display: block;
    }
}

.question-text-section {
    padding: 30px;
    height: 100%;
    background: url("Images/layout/patroon.jpg") no-repeat;
    background-size: cover;
    color: @color-white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .heading {
        color: @color-white;
    }
}

.question-block-logo {
    padding: 30px 30px 0px 30px;

    img {
        max-height: 170px;
        margin: 0 auto;
        display: block;
    }
}

.question-block-person-image {
    padding: 20px 0px 20px 20px;

    /*img {
        max-height: 170px;
        margin: 0 auto;
        display: block;
    }*/
}

.plectrum-person {
    .plectrum-person-plectrum {
        background: url("images/icons/svg-overlay-person.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 100px;
        height: 86px;
        position: relative;
        z-index: 1;
        top: 86px;
        margin-top: -86px;
        display: block;
    }

    .plectrum-person-image {
        width: 98px;
        height: 84px;
        object-fit: cover;
        position: relative;
        z-index: 0;
        display: block;
        padding: 1px;
    }

    @media (min-width: @breakpoint-xl) {
        .plectrum-person-plectrum {
            width: 140px;
            height: 120px;
            top: 120px;
            margin-top: -120px;
        }

        .plectrum-person-image {
            width: 138px;
            height: 118px;
        }
    }
}

.question-block-content {
    padding: 20px 30px 20px 30px;
}

.question-block-telephone {
    padding: 10px 30px 30px 30px;
}

.question-block-contact-information {
    border-top: 1px solid @color-grey;
    padding: 20px 30px;
}

.contact-information-spacer {
    color: @color-red;
}

.contact-information-text {
    display: inline;
}

.phone-icon {
    margin-right: 7px;

    img {
        height: 25px;
        margin-top: -4px;
    }
}

.phone-number {
    display: inline;
}

@media (min-width: @breakpoint-md) {
    .contact-information-text {
        display: inline;
    }

    .question-block-logo {
        padding: 30px 0px 30px 30px;
    }

    .question-block-content {
        padding: 30px 30px 0px 30px;
    }

    .question-text-section {
        border-top-left-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
    }
}
