﻿
.paymentOverview {

	td, th {
		border-bottom: black solid 1px;
		padding: 0.5em 0.2em;
	}
}

.subscription-status-table td {
    padding-right: 15px;
    vertical-align: top;

    &:last-child {
        padding-right: 0;
    }
}