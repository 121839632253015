﻿@import (reference) '../_Variables.less';

.homepage-logos-section {
    background: @color-white;

    .logos-container {
        margin-bottom: 40px;
    }

    .logo-container {
        padding: 15px;
        margin-top: 40px;

        img {
            max-height: 75px;
            margin: 0 auto;
            display: block;
        }
    }
}