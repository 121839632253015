﻿@import (reference) '../_Variables.less';

.menu-bar {
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    background-color: @color-white;

    &.scroll {
        .logo img {
            height: 40px;
            padding: 0px;
        }

        .toggle {
            height: 60px;
        }
    }

    .logo {
        height: auto;
        padding: 10px;

        img {
            height: 80px;
            padding: 10px;
            transition: all .5s;
        }
    }

    .open a.dropdown-toggle:focus, .open a.dropdown-toggle:active {
        background-color: @color-white;
    }

    .toggle {
        height: 100px;
        padding: 0px;
        float: right;
        border: none;
        display: flex;
        align-items: center;
        padding: 15px;
        transition: height .5s;

        .hamburger {
            float: right;
            margin-left: 10px;

            .bar {
                width: 35px;
                height: 3px;
                background-color: #333;
                margin: 6px 0;
                border-radius: 30px;
                transition: 0.4s;
            }
        }

        &:not(.collapsed) {
            .bar:nth-child(1) {
                -webkit-transform: rotate(-45deg) translate(-5px, 7px);
                transform: rotate(-45deg) translate(-5px, 7px);
            }

            .bar:nth-child(2) {
                opacity: 0;
            }

            .bar:nth-child(3) {
                -webkit-transform: rotate(45deg) translate(-6px, -8px);
                transform: rotate(45deg) translate(-6px, -8px);
            }
        }
    }

    .menubar-items {
        border: none;
        height: auto;
        transition: margin-top .5s;

        &.collapse {
            display: none;

            &.in {
                display: block;
            }
        }

        ul {
            margin-top: 0px;
            margin-bottom: 0px;

            li {
                &:before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: @color-grey;
                    display: block;
                }

                .back {
                    display: none;
                    margin-left: 15px;
                    float: left;
                    border-left: 1px solid @color-grey;
                    border-right: 1px solid @color-grey;
                    padding: 20px;
                    line-height: 20px;

                    img {
                        width: 15px;
                        height: 15px;
                    }
                }

                a {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    color: @color-darkgrey;

                    .dropdown-icon {
                        float: right;

                        img {
                            height: 15px;
                            width: 15px;
                        }

                        &.down {
                            display: none;
                        }
                    }
                }

                a, a:focus, a:hover {
                    background-color: @color-white;
                }

                .dropdown-menu {
                    padding: 0px;
                    font-size: inherit;
                    border: none;

                    li a {
                        font-weight: inherit;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        color: @color-darkgrey;
                    }
                }
            }

            li.open {
                .back {
                    display: block;
                }

                .dropdown-toggle {
                    overflow: hidden;
                }

                .dropdown-icon {
                    display: none;
                }
            }

			li.highlighted-link{
				a {					
					color: @color-darkgrey;
				}
				&.gold a {
					background-color: @color-beige;
				}
				&.green{
					a {
						background-color: @color-green2;
					}
				}
				&.red{
					a {
						background-color: @color-red;
					}
				}
			}
        }

        &.submenu-active > ul > li:not(.open) {
            display: none;
        }
    }

    @media (max-width: 400px) {
        .logo img {
            height: 65px;
        }

        .toggle {
            height: 85px;
        }
    }

    @media (min-width: @breakpoint-xl) {

        .toggle {
            display: none;
        }

        &.scroll .menubar-items {
            margin-top: 0px;
        }

        .menubar-items {
            margin-top: 40px;

            > ul > li:before {
                display: none;
            }

            ul li {
                &.open .back {
                    display: none;
                }

                a {
                    .dropdown-icon.right {
                        display: none;
                    }

                    .dropdown-icon.down {
                        display: block;
                        padding-left: 5px;
                    }
                }
            }
        }

        .menubar-items.submenu-active > ul > li:not(.open) {
            display: block;
        }
    }

    @media (max-width: @breakpoint-xl) {
        overflow-y: auto;

        > .container {
            display: flex;
            flex-direction: column;
        }

        .nav:after, .navbar-collapse:after, &:after, .navbar-header:after, .container:after {
            content: initial;
            display: none;
        }
    }
}
