﻿/* File Created: November 9, 2012 */
@import "elements.less";

@boxDropOpacity: 0.2;

.medilexboxshadow {
	.drop-shadow(5px,5px,5px,@boxDropOpacity);
}


img {
	max-width: 100%;
}

body {
    background: none;

    form textarea {
        background: none;
        -moz-box-shadow: inset 0 0 10px 0px #aaa;
        -webkit-box-shadow: inset 0 0 10px 0px #aaa;
        box-shadow: inset 0 0 10px 0px #aaa;
        max-width: 100%;

        @media only screen and (min-width : 500px) {
            min-width: 300px;
        }
    }

    form .editor-label {
        width: 30%;
    }

    form .editor-field {
        width: 65%;
    }

    #center {
        #header img {
            max-width: 100%;
        }

        max-width: 1200px;
        min-width: 320px;
        width: 96%;
        padding-left: 1%;
        padding-right: 1%;

        @media only screen and (max-width : 800px) {
            width: 98%;
            padding-left: .5%;
            padding-right: .5%;
            padding-top: 10px;
        }

        @media only screen and (min-width : 1000px) {
            /*padding-left:2%;
			padding-right:2%;*/
        }

        background-color: #e4e4e4;
        border: 0px solid #999;
        border-top: 0;
        .drop-shadow(0,0,15px,0.4);

        .lt-ie8 & {
            width: 1000px;
        }
    }

    .bigcolumn {
        background: #F3F4F2;

        .top {
            background: none;
            .medilexboxshadow;
            padding: 0;
        }

        padding: 0;
        margin-bottom: 12px;
    }

    .teaserheader {
        margin-left: 0;
        margin-bottom: 12px;
        position: relative;

        .teasertext {
            background: #fff;
            margin-left: 23px;
            height: 165px;
            .medilexboxshadow;
            width: 27%;
            padding: 0;
            margin-right: 0;

            p {
                width: 200px;
                margin: 10px auto;
            }

			position: absolute;
			right: 0px;
			float: none;
			/*
			@media only screen and (max-width : 866px) {
				left: auto;
				right:-26px;
			}*/
        }
    }

    .textcolumn .box .branches li {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 40%;

        @media only screen and (min-width : 550px) and (max-width : 899px) {
            width: 28%;
        }

        @media only screen and (min-width : 900px) {
            width: 20%;
        }
    }

    @media only screen and (max-width : 750px) {
        #brancheselector {
            height: 30px;
            float: none;
            width: auto;
            margin-bottom: 5px;
        }
    }

    @media only screen and (max-width : 400px) {
        #brancheselector {
            display: none;
            padding-right: 0;
            height: auto;
            padding-bottom: 5px;

            ul {
                float: none;
                position: inherit;
                top: auto;
                left: auto;
            }

            p {
                float: none;
            }
        }
    }

    .contentcolumns {
        background: none;
    }

    @media only screen and (max-width : 699px) {
        .contentcolumns {
            padding: 0;
            margin-top: 0;
        }

        .textcolumn {
            margin-top: 10px;
        }
    }

    .textcolumn {
        background: url("@{images_path}backgroundcorner.svg") no-repeat top left;
        width: 100%;
        padding-top: 22px;
        margin-bottom: 10px;
        padding-bottom: 0;
        position: relative;

        @media only screen and (min-width : 700px) {
            &:after {
                @arrowWidth: 29px;

                content: " ";
                position: absolute;
                top: 0;
                right: -1 * @arrowWidth;
                width: @arrowWidth;
                height: 22px;
                display: block;
                background: url("@{images_path}backgroundcorner.svg") no-repeat top right;
            }
        }

        .desktop {
            width: 70%;
            .medilexboxshadow;
        }

        .lt-ie9 & {
            .desktop;
        }

        @media only screen and (min-width : 700px) {
            .desktop;
        }

        .columnbottom {
            background: none;
            background: #fff;
            padding: 0% 2% 1% 2%;
        }

        p, ul {
            width: auto;
        }

        .box {
            width: auto;
            margin-top: 10px;
        }
    }

    .relatedcolumn {
        width: 100%;
        margin-left: 0;

        @media only screen and (max-width : 699px) {
            padding-top: 30px;
        }

        .relatedtopimage {
            margin-top: -62px;
        }

        .desktop {
            float: right;
            width: 29.5%;
            margin-left: -18px;
        }

        .lt-ie9 & {
            .desktop;
        }

        @media only screen and (min-width : 700px) {
            .desktop;
        }

        .relatedbox {
            .medilexboxshadow;
            margin-bottom: 15px;

            h3 {
                background: #fff;

                &.topbox {
                    padding-top: 15px;
                    height: auto;
                    background: url("@{images_path}backgroundcorner.svg") no-repeat top left;

                    span {
                        top: auto;
                        margin-top: 2px;
                    }
                }
            }

            .hidden {
                height: 10px;
                padding: 0;
            }

            .box {
                background: #fff;
            }
        }
    }

    .nextcongres, .medewerkershort, .nextcustomcongres {
        .img {
            margin-right: 5px;
        }
    }

    .nextcongres, .medewerkershort, .userteaser {
        width: auto;
    }

    .userteaser {
        float: none;
    }

    .nextcustomcongres {
        width: auto;
    }

    .congressubpages .congrestextcontent, .subpages .textcolumn, .subpages .textcontent, .widetext {
        width: 100%;

        @media only screen and (min-width : 768px) and (max-width : 1000px) {
            width: 70%;
        }

        @media only screen and (min-width : 1000px) {
            width: 70%;
            padding-left: 8%;
            line-height: 1.8;
        }

        p, ul, ol {
            width: 100%;
        }
    }

    .widetext {
        @media only screen and (min-width : 768px) {
            width: 100%;
            max-width: 767px;
            padding-left: 0;
        }
    }

    .congressubpages .congressubpageslist, .subpages .subpageslist {
        @media only screen and (max-width : 767px) {
            width: 100%;

            ul {
                list-style-position: none;
            }

            li {
                display: inline-block;
                min-height: 2.5em;
            }

            a {
                background: #6292A0;
                color: #fff;
                text-transform: uppercase;
                border: 1px solid #4C8493;
                padding: 3px 3px;
                font-family: Arial;
                font-size: 12px;
            }
        }
    }

    .teaserimg {
        max-width: 840px;
        width: 70%;
        overflow: hidden;
        background: #f1eeeb;
        height: 165px;
        .medilexboxshadow;

        &.custom {
            background: #f3f4f2;

            img {
                @media only screen and (min-width : 1041px) {
                    /*
					width: 100%;
					height: auto;
					//margin-top: -10px;
					top: auto;
					bottom: 0;*/
                    transform: translateX(-50%);
                    left: 50%;
                    max-width: 840px !important;
                    width: auto;
                }

                @media only screen and (max-width : 1040px) {
                    top: auto;
                    bottom: 0;
                    max-height: 165px;
                    max-width: 120% !important;
                    width: auto;
                    height: auto;
                    left: -10%;
                    right: -10%;
                }

                @media only screen and (min-width : 1188px) {
                    width: 100%;
                    height: auto;
                    /*	margin-bottom: -10px;*/
                    top: 0;
                    bottom: auto;
                }
                /*
				@media only screen and (min-width : 1080px) and (max-width : 1164px) {
					left: 55%;
					margin-left: -50%;
					min-width: 800px;
                    width:auto;
				}

				@media only screen and (min-width : 980px) and (max-width : 1079px) {
					left: 50%;
					margin-left: -50%;
					min-width: 725px;
                    width:auto;
				}

				@media only screen and (min-width :898px) and (max-width : 979px) {
					left: 50%;
					margin-left: -50%;
					min-width: 660px;
                    width:auto;
				}

				@media only screen and (min-width :745px) and (max-width : 830px) {
					left: 50%;
					margin-left: -50%;
					min-width: 560px;
                    width:auto;
				}

				@media only screen and (min-width :640px) and (max-width : 744px) {
					left: 50%;
					margin-left: -50%;
					min-width: 510px;
                    width:auto;
				}
                    */
            }
        }

        &.normal {
            img {
                @media only screen and (min-width : 1060px) {
                    min-width: 100%;
                    height: auto;
                    min-height: 165px;
                    margin-left: 0;
                    width: auto;
                    left: 0;
                }

                @media only screen and (min-width : 1120px) {
                    /*	margin-top:-10px;*/
                }

                @media only screen and (max-width : 979px) {
                    bottom: 0;
                    width: auto; /*100%;*/
                    height: 165px;
                    top: auto;
                }

                @media only screen and (min-width : 980px) and (max-width:1059px) {
                    min-width: 712px;
                    margin-left: -356px;
                    left: 50%;
                }
            }
        }
    }

    @media only screen and (max-width : 780px) {
        .teaserimg p {
            font-size: 16px;
        }
    }

    .breadcrumb {
        margin-top: 10px;
    }

    @media only screen and (max-width : 699px) {
        .teaserheader {
            display: none;
        }
    }

    @sizeFactor: .75;

    @media only screen and (max-width : 480px) {
        .menu li a {
            padding: 3px 3px;
            text-transform: none;
            font-size: 12px;

            strong {
                /*display: none;*/
            }
        }
    }

    @media only screen and (max-width : 699px) {
        .nextcongres, .medewerkershort, .nextcustomcongres {
            position: relative;

            .img {
                width: floor(@sizeFactor * 130px);
                height: floor(@sizeFactor * 111px);
                overflow: hidden;

                img {
                    left: @sizeFactor * 6px;
                    top: @sizeFactor * 9px;
                    width: floor(@sizeFactor*121px);
                    max-height: floor(@sizeFactor*91px);
                }

                .mal {
                    background-size: @sizeFactor * 128px;
                    width: @sizeFactor * 128px;
                    height: floor(@sizeFactor * 111px);
                }
            }

            h3 {
                font-size: 150%;
                margin-top: 0.2em;
            }

            .congressbuttons {
                position: absolute;
                right: 0;
                top: 3px;
            }
        }
    }
}
