﻿@import (reference) '../_Variables.less';

.congress-filters {
    position: relative;
    margin-top: -130px;
    margin-bottom: 0px;
    z-index: 2;

    .row {
        margin-left: -8px;
        margin-right: -8px;
    }

    .select-row, .button-row {
        padding: 0px 8px;
    }

    .congress-filters-block {
        background-color: @color-white;
        border-radius: 10px;
        padding: 35px;
    }

    .filter-column {
        margin-bottom: 10px;
        padding: 0px 8px;
    }

    .select-select2-congressearch + .select2 {
        margin-bottom: 10px;
    }

    .select-select2 {
        width: 100%;
        height: 50px !important;
    }

    @media(min-width:@breakpoint-xl) {
        margin-bottom: 25px;

        .filter-column {
            margin-bottom: 0px;
        }

        .select-select2-congressearch + .select2 {
            margin-bottom: 0px;
        }
    }

    &.overview {
        margin-top: -30px;
        margin-bottom: 0px;

        @media(min-width:@breakpoint-xl) {
            .filter-column {
                margin-bottom: 30px;
            }
        }
    }
}

.select2 .custom-select2 {
    background-color: @color-grey3;
    border: 1px solid @color-grey;
    height: 50px !important;

    .select2-selection__rendered {
        line-height: 30px !important;
        padding: 10px !important;
    }

    .select2-selection__arrow {
        height: 50px !important;
        background: url(images/icons/arrow-down.svg) no-repeat 50%;
        right: 10px;
        width: 11px;

        b {
            display: none;
        }
    }
}

.custom-select2-dropdown {
    .select2-results > .select2-results__options {
        max-height: 300px;
    }
}

.select-select2-congressearch + .select2 {
    width: 100% !important;

    .select2-search, .select2-search__field {
        width: 100% !important;
        line-height: normal;
    }
}