﻿/// <reference path="../_variables.less" />

.courseLink {

    a {
        &, &:visited {
            color: @color-primary;
        }

        &:hover {
            color: @color-secondary;
        }
    }
}

.childCourseMessage {
    border: 1px solid lighten(@color-primary,20%);
    padding: 10px;
    margin-bottom: 10px;

    p {
        margin: 0;
    }

    .courseLink;

    @media only screen and (min-width: 700px) {
        //  width: 150px;
        //  float: right;
        //  margin-left: 10px;
        //margin-right: -39px;
    }
}

.courseSubscribeSelection {

    .ml-button.isSoldoutButton {
        background: #808080;

        &:hover {
            background: @color-primary;
        }
    }

    .isSoldout {
        font-style: normal !important;
        font-weight: bold;
        color: @color-primary;
        text-transform: uppercase;
        font-size: 1.2em;
        line-height: 1;
        vertical-align: text-top;
        margin-left: 0.5em;
    }

    .course-days-header, .course-days {
        color: #4d4d4d;
    }

    .course-days {
        font-style: italic;
    }
}
