﻿@import (reference) '../_Variables.less';

.image-text-section {
    overflow: hidden;

    .row {
        height: 100%;
        margin: 0px;
    }

    .image-section {
        padding: 0;
        height: 400px;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    .text-section {
        padding: 0;
        height: auto;
        background: url("Images/layout/patroon.jpg") no-repeat;
        background-size: cover;
        color: @color-white;
    }

    .text {
        padding: 40px 20px 100px 20px;
    }

    .heading {
        margin-bottom: 30px;
        color: @color-white;
    }

    @media(min-width: @breakpoint-md) {
        height: 700px;

        .image-section, .text-section {
            height: 100%;
        }

        .text {
            padding: 40px 20px 100px 20px;
        }
    }

    @media (min-width: @breakpoint-xl) {
        .text {
            width: 50%;
            padding: 70px 0px 100px 80px;
        }
    }
}
