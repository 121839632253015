﻿@import (reference) '../_Variables.less';

.contactblocks {
    margin-top: 30px;

    .contactblock {
        border: 1px solid @color-grey;
        border-radius: 10px;
        padding: 25px;
        margin-bottom: 30px;
        font-size: 15px;

        .contactblock-icon {
            height: 40px;
            display: block;
            margin-bottom: 20px;
        }
    }

    a, a:hover {
        color: @color-darkgrey;
        text-decoration: underline;
    }
}

@media(min-width:@breakpoint-xl) {
    .contactblocks .contactblock {
        height: 250px;
    }
}