﻿/// <reference path="../_variables.less" />

.speaker-link {
	display: none;
}

.eventProgram {
	position:relative;
}

@media only screen and (min-width: 768px) {
	.speaker-link-container {

		.speaker-link {
			display: block;
			margin-top: 3px;
			margin-left: -22px;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 5;
		}

		a:hover {
			color: @color-secondary;
		}

		&:hover a {
			color:@color-secondary;
		}

		a:hover .speaker-link {
			z-index: 10;
		}
	}
}
