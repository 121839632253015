﻿/// <reference path="../_variables.less" />

.user-menu {
	background: @color-primary;
	font-family: Arial;
	font-size: 11px;
	color: #fff;

	a {
		&, &:visited {
			color: #fff;
		}

		&:hover {
		}
	}

	.user-menu-container {
		max-width: 1200px;
		min-width: 320px;
		width: 96%;
		margin: 0 auto;
		padding: 5px 1%;
	}

	.user-actions {
		text-align: right;
	}

	.ml-button, .ml-button:visited {
		background: #6292A0;
		color: white;

		&:hover {
			background: #ddd;
		}
	}

	.user-logged-in {

		.ml-button {
			text-transform: none;
		}
	}

	.user-dropdownmenu {
		position: relative;
		display: inline-block;
		border-bottom: 0;
		margin-top: -1px;
		padding-left: 5px;
	}

	.user-menu-toggle {
		display: none;
	}

	.is-toggled {
		background: #fff;
		border: 1px solid #CCCCCC;
		border-bottom: 0;
		color: @color-secondary;

		.ml-button {
			background: #fff;
			color: @color-secondary;
			padding-left: 0;

			&:hover {
				color: @color-primary-alt1;
			}
		}

		.user-menu-toggle {
			display: block;
			position: absolute;
			background: #fff;
			border: 1px solid #CCCCCC;
			border-top: 0;
			left: -1px;
			right: -1px;
			text-align: left;
			padding: 6px 5px 3px 5px;
			z-index: 150;
			line-height: 2.3;

			a {
				display: inline-block;

				&, &:visited {
					color: @color-secondary;
				}

				&:hover {
					color: @color-primary-alt1;
				}
			}
		}
	}


	.shoppingcart-top-link {
		display: inline-block;

		a {
			line-height: 2;

			.icon-shoppingcart {
				background-position-y: -32px;
				vertical-align: text-bottom;
				margin-bottom: -1px;
			}

			padding: 1px 10px;
			display: inline-block;

			&, &:visited {
				color: #fff;
			}

			&:hover {
				background: lighten(@color-primary-alt1, 10%);
				color: #fff;
			}
		}
	}
}
