﻿.table {
	
	&.table-default {
		
		td,th {
			vertical-align:top;
		}

		th {
			font-weight:bold;
		}
	}

}
