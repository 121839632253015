﻿@import (reference) '../_Variables.less';

form .formfield {
    margin: 0;
    margin-bottom: 20px;
    min-height: 80px;
}

body form .editor-label {
    width: 100%;
}

body form .editor-label .required, strong.required {
    color: @color-grey;
}

body form .editor-label label {
    margin-bottom: 0px;
}

body form .editor-field {
    width: 100%;
}

body form textarea {
    min-width: initial;
    width: 100%;
}

form .text-box.single-line,
body form textarea {
    width: 100%;
    background: @color-grey3;
    border: 1px solid @color-grey;
    border-radius: 5px;
    box-shadow: none;
    padding: 3px 15px;

    &:focus {
        border: 1px solid @color-darkgrey;
        outline: none;
    }

    &.input-validation-error {
        border: 1px solid @color-red;
    }
}

form .text-box.single-line {
    height: 50px;
}

body form textarea {
    height: 150px;
}

form .field-validation-error {
    color: @color-red;
    font-style: italic;
    width: 100%;
}

.subscribeOptionForm {
    background-color: @color-white;
    font-size: inherit;
    line-height: inherit;
}

.frmobj h3 {
	margin-top: 30px;
	margin-bottom: 20px;

	.small {
		font-family: 'Open Sans';
		font-size: 15px;
		color: @color-darkgrey;
	}
}

//Fix for some bootstrap elements which are unintentionally used in the old views
.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
    position: initial;
    margin-left: 0px;
    margin-top: 0px;
}

.radio label, .checkbox label {
    min-height: initial;
    padding-left: initial;
    font-weight: inherit;
    margin-bottom: inherit;
}

.icheckbox_minimal-grey, .iradio_minimal-grey {
    margin-right: 6px;
	margin-top: 6px;
	vertical-align: top;
}

.autocompleteDropdown {
    width: 100%;
}

.autocompleteDropdown-toggle {
    background-color: @color-red;
}

.ui-selectmenu-button {
    border: 1px solid @color-grey;
    height: 50px !important;
    width: 100%;
    background: @color-grey3;

    span.ui-selectmenu-text {
        font-size: @font-size-main-base;
        padding-top: 13px;
        padding-left: 7px;
        text-overflow: initial;
    }

    span.ui-icon {
        background: url(images/icons/arrow-down.svg) no-repeat 50% !important;
        right: 5px;
        width: 11px;
    }

    &.ui-corner-top {
        background-image: none !important;
        color: inherit;
    }
}

.editor-field label{
    font-weight: normal;
}

.ui-dialog-titlebar-close {
    background-position: -96px -128px;
    background-image: url(images/ui-icons_888888_256x240.png);
}

.accreditationButton .ui-button {
    border: none;
    background-color: #E7CC92;
}

.subscribeOptionForm {
    p {
        @media (min-width: 600px) {
            width: 50%;
            float: left;
        }
    }

    .row > * {
        padding: 0px 15px;
    }
}

// Livestream
.livestream-row {
	margin-bottom: 2em;

	h2 {
		margin-bottom: 10px;
	}
}
// Chat
.chatbox-wrapper {
	.input-group {
		margin-bottom: 2em;
	}

	.text-box {
		background: @color-grey3;
		border: 1px solid @color-grey;
		border-radius: 5px;
		box-shadow: none;
		padding: 3px 15px;
		height: 50px;
		width: 80%;
		display: inline-flex;
	}

	.chatitem {
		margin-bottom: 10px;

		.chat-message {
			padding: .5rem;
			background-color: fade(@color-darkgrey, 10%);
			border-radius: 5px;
		}

		.ismoderator {
			color: @color-red
		}
	}
}
