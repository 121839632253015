﻿/// <reference path="../_variables.less" />
.cookie-info {
	background-color: @color-primary;
	font-size: 12px;
	overflow: auto;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;	

	.container-cookie-info {
		max-width: 96vw;
		min-width: 320px;
		display: flex;
		flex-wrap: wrap;
		width: 94vw;
		margin: 0 auto;
		padding-top: 1em;
		padding-bottom: 1em;

		.column {
			display: flex;
			flex-basis: 100%;
			align-items: center;
			align-content: center;
			justify-content: center;

			&:last-child {
				margin-top: 1rem;
			}

			@media only screen and (min-width: @breakpoint-xl) {
				flex: 1 auto;
				justify-content: space-between;

				&:last-child {
					margin-top: 0;
					justify-content: flex-end;
				}
			}
		}

		.btn-link {
			margin-right: 1rem;
		}

		.ml-button:hover, .ml-button:visited {
			background: #ddd;
		}

		.ml-button.spacing {
			margin-right: 1em;
		}

		.cookie-content {

			.cookie-header {
				font-size: 1.2em;
				font-weight: bold;
				margin-bottom: .5em;
			}

			p {
				margin-bottom: 0;
			}
		}

		a.cookie-more-info {
			color: #fff;
			text-decoration: underline;
		}
	}

	&.hidden {
		display: none !important;
		visibility: hidden;
	}
}

