﻿/// <reference path="../_variables.less" />

.invoicemethod-selector {
	ul {
		list-style-type: none;

		&, > li {
			padding: 0;
			margin: 0;
			list-style-type: none;

            
		}

        > li {
            input {
                vertical-align:middle;
            }

            line-height:2;
            
            @media only screen and (min-width : 960px) {
                line-height:1.8;
            }
        }
	}

	.editor-field {
		.required {
			display:none;
		}
	}
}
