﻿@import (reference) '../_Variables.less';
@import (reference) '_mixins.less';

.congressdetail-mobile-controls {
    .congressmenu-item:first-of-type {
        border-top: 1px solid @color-grey;
    }

    .mobile-congressmenu {
        display: flex;
        padding: 15px 20px;
        justify-content: space-between;
    }

    .hamburger {
        width: 25px;
        height: 20px;
        margin-top: 3px;

        .bar {
            width: 100%;
            height: 2px;
            background-color: @color-red;
            margin-top: 5px;
            border-radius: 30px;
        }
    }

    .congressmenu-item {
        background-color: @color-white;
    }

    .button {
        margin-bottom: 10px;
    }

    .button:not(:last-of-type) {
        margin-right: 10px;
    }
}

.congressdetail-mobile-controls.bottom {
    padding: 0;
}

.congressheader {
    .congressheader-inline {
        border-top: 1px solid @color-grey;
        padding: 15px 0px;
        .font-size(@font-size-small + 1px)
    }

    .congressheader-inlinecontent {
        display: inline-block;
        margin-right: 20px;
    }

    .important-information {
        background-color: @color-beige;
        padding: 3px 8px;
        border-radius: 4px;
    }


    @media(min-width:@breakpoint-md) {
        margin-top: 15px;
    }
}

.congressdetail-tab {
	.cms {
		ul {
			padding-left: 30px;
			margin-bottom: 30px;
			list-style-image: url('images/icons/arrow-right.svg') !important;

			li {
				padding-left: 3px;
				list-style: inherit;
                margin: 0;
			}

            ul {
                margin-bottom: 0;
            }
		}
	}

	.reviews-wrap {
		margin-top: 30px;

		.review-item {
			margin-bottom: 30px;
			position: relative;

			&::before {
				content: "";
				background: url(images/icons/comment.svg) no-repeat;
				width: 15px;
				height: 15px;
				position: absolute;
				left: -30px;
				top: 5px;
			}

			p {
				font-style: italic;
			}
		}
	}

	.time {
		&.time-icon {
			position: relative;

			&::before {
				content: "";
				background: url(images/icons/clock.svg) no-repeat;
				width: 15px;
				height: 15px;
				position: absolute;
				left: -30px;
				top: 5px;
			}
		}
	}
}


.sidebar-buttons .button-container {
	max-width: 180px;

	.button {
		width: 100%;
		margin-bottom: 10px;
		text-align: center;
	}
}

.page-sidebar .congressubpages{
    margin-top: 50px;
}

.subscribe-steps-container {
    .step {
        width: 40px;
        height: 40px;
        background-color: @color-grey3;
        border-radius: 20px;
        border: 1px solid @color-grey;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        margin-left: 15px;
    }

    .step.done, .step.active {
        float: left;
        margin-right: 15px;
        margin-left: 0px;
    }

    .steplabels {
        display: none;
    }

    .labelcontainer {
        width: 40px;
    }

    .label {
        width: auto;
        position: relative;
        white-space: nowrap;
        margin: 0 auto;
        text-align: center;
        margin: 0 -1000%;
    }

    .lastlabel {
        display: none;
    }

    .lastlabeltext {
        text-align: right;
        position: relative;
        top: -30px;
    }

    .labelcontainer:last-child .label {
        display: none;
    }

    .labelcontainer:first-child .label {
        text-align: left;
        margin: 0;
    }

    .active .step-active, .done .step-active {
        width: 24px;
        height: 24px;
        border-radius: 12px;
    }

    .active .step-active {
        background-color: @color-red;
    }

    .done .step-active {
        background-color: @color-grey;
    }

    .line {
        width: 100%;
        border-bottom: 1px solid @color-grey;
        top: 20px;
        position: relative;
    }

    @media(min-width:@breakpoint-md) {
        .steps {
            display: flex;
            justify-content: space-between;
        }

        .step, .step.done, .step.active {
            float: none;
            margin: 0;
        }

        .steplabels {
            display: flex;
            justify-content: space-between;
        }

        .lastlabel {
            display: block;
        }
    }
}

.custom-needshare {
    position: relative;
    float: right;
    right: -20px;
    top: -20px;
    margin-bottom: -10px;

    .need-share-button_button {
        background: url("Images/icons/share.svg") no-repeat 50% 0%;
        border: none;
        padding-top: 55px;
        background-size: 60px;
        .font-size(@font-size-small);
    }

    .need-share-button_dropdown {
        height: 40px;
        width: auto;
        white-space: nowrap;
        margin-left: -140px !important;
    }

    .need-share-button_link::after {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        margin-left: 10px;
        margin-top: 10px;
    }

    .need-share-button_whatsapp::after {
        background: url("Images/icons/share-whatsapp.svg")
    }

    .need-share-button_facebook::after {
        background: url("Images/icons/share-facebook.svg")
    }

    .need-share-button_twitter::after {
        background: url("Images/icons/share-twitter.svg")
    }

    .need-share-button_linkedin::after {
        background: url("Images/icons/share-linkedin.svg")
    }

    .need-share-button_instagram::after {
        background: url("Images/icons/share-insta.svg")
    }

    .need-share-button_mailto::after {
        background: url("Images/icons/share-mail.svg")
    }

    .need-share-button_link:not(:last-child) {
        border-right: 1px solid @color-grey3;
    }

    @media(min-width:@breakpoint-md) {
        right: -40px;
        top: -40px;
        margin-bottom: -50px;
    }
}

.social-media-share-inline li:last-child {
    vertical-align: top;
}

.downloadprogramme {
    background-image: url("Images/icons/download.svg");
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: 10px 50%;
    padding-left: 45px;
}