﻿@import (reference) '../_Variables.less';
body{
	.blog-page {
		.text-content p {
			margin-bottom: 15px;
		}
		.delve {
			height: 380px;
		}

		.paging {
			.paging-block {
				&.active {
					cursor:auto;
				}

				a {
					color: @color-darkgrey;
					text-decoration: none;
				}
			}
		}
	}
}