﻿li.institution-table {
	display: table;
	width: 100%;
	max-width: 640px;
	min-width:320px;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;

	.institution-row {
		display: table-row;

		.selectmencol {
			display: table-cell;
			width: 33.333%;
		}
	}

	&.ui-state-focus {
		border:0;
		margin:0;
	}

	&.ui-state-disabled {
		opacity:1;
	}
}


