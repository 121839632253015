﻿@import (reference) '../_Variables.less';

.quotes-section {
    padding: 35px 0px;

    .quotes-block {
        text-align: center;
        padding: 30px 0px;
    }

    .quote-icon {
        display: inline-block;

        img {
            height: 20px;
        }
    }

    .quotes-slider-content {
        max-width: 800px;
        margin: 0 auto;
    }

    .spacing-icon {
        width: 30px;
        height: 2px;
        display: inline-block;
        background-color: @color-grey;
        vertical-align: middle;
        margin: 0px 10px;
    }

    .bx-wrapper {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 0;
        background: inherit;
    }

    .bx-wrapper .bx-pager .bx-pager-link {
        height: 15px;
        width: 15px;
        border-radius: 15px;
        background-color: @color-white;
        border: 2px solid @color-grey;

        &:hover {
            background-color: @color-white;
        }

        &.active {
            background-color: @color-red;
            border: 0;

            &:hover {
                background-color: @color-red;
            }
        }
    }

    @media(min-width:@breakpoint-md) {
        .quotes-block {
            background-color: @color-white;
            border-radius: 10px;
            padding: 30px 30px;
        }
    }
}