﻿@import (reference) '../_Variables.less';

body .medewerkershort {
    height: 350px;
    background-color: @color-white;
    border-radius: 10px;
    border: 1px solid @color-grey;
    color: @color-darkgrey;

    .img {
        width: 100%;
        height: 225px;
        position: static;
        float: none;

        img {
            width: 100%;
            height: 225px;
            object-fit: cover;
            position: static;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            max-height: initial;
        }
    }

    .userteaser {
        padding: 30px;
    }

    &:hover {
        color: inherit;
    }

    .link-plectrum {
        position:relative;
        img {
            height: 40px;
            position: absolute;
            top: -20px;
            right: 20px;
        }
    }
}

.employeelink:hover {
    color: @color-darkgrey;
    text-decoration: none;
}