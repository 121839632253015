﻿.selectperiodictraining {
    &, & * {
        box-sizing: border-box;
    }

    .periodictrainingtypes {
        text-align: center;
        padding: 0;
        margin: 0;
        line-height: 35px;
        margin: 0 -5px;

        .periodictrainingtype {
            display: block;
            padding: 0;
            margin: 0;
            list-style-type: none;
            width: 66.66%;
            padding: 5px;
            font-size: 17px;
            vertical-align: middle;
            margin: 0 auto;

            a img {
                margin: 0 auto 0 auto;
                display: block;
            }

            @media only screen and (min-width : 320px) {
                max-width: 33.333%;
                width: 33.333%;
                float: left;
                font-size: 16px;
            }


            a {
                display: block;
                border-radius: 5px;
                vertical-align: middle;
                padding-top: 10px;

                img {
                    vertical-align: middle;
                }

                &, &:active {
                    background-color: @color-primary;
                    color: #fff;
                }

                &:hover, &:focus {
                    background-color: @color-primary;
                }
            }


            @media only screen and (min-width : 480px) {
                font-size: 18px;

                a {
                    padding-top: 0;

                    & img {
                        margin: 0;
                        margin-right: 0.5em;
                        display: inline-block;
                    }
                }
            }
        }
    }

    .periodictrainingfilters {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 30px;

        .periodictrainingfilter, .periodictrainingfilter-label {
            line-height: 2;
        }

        @media only screen and (min-width : 880px) {
            .periodictrainingfilter, .periodictrainingfilter-label {
                display: inline-block;
                line-height: 1;
            }
        }

        .periodictrainingfilter-label {
            margin-left: 10px;
            margin-right: 10px;
        }

        .periodictrainingfilter-input, .custom-combobox-input {
            min-width: 230px;
        }

        .periodictrainingfilter {
            .periodictrainingfilter-input {
                line-height: 25px;
                min-height: 25px;
                border: 1px solid #cccccc;
            }

            .custom-combobox-input {
                .periodictrainingfilter-input;
                color: #000;
                font-size: 14px;
                background: #fff;
                padding-left: 0.4em;
                background-image: url('../Images/search.svg');
                background-repeat: no-repeat;
                background-position: right 5px center;
            }
        }
    }

    .periodictraining-btn {
        background-color: @color-primary;
        color: #fff;
        border: 0;
        line-height: 25px;
        padding: 0 10px;
        border-radius: 5px;
        -webkit-appearance: none;

        &:hover, &:focus {
            background-color: @color-primary;
        }
    }

    select.periodictrainingfilter-input {

        option {
            color: black;

            &[selected] {
                color: #4d4d4d;
            }
        }
    }

    select.periodictrainingfilter-input, .periodictrainingfilter-input option[disabled], ::placeholder {
        color: #4d4d4d;
    }
}
