﻿.medilexmenu {
    
    text-align: right;
    

    li {
        text-align:left;
        float:none;
        
        display: inline-block;
    }

    li:nth-child(1),li:nth-child(2) {
        float:left;
    }

    li:nth-child(3) {
        border-left: 1px solid #fff;
    }
}