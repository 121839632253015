﻿@import (reference) '../_Variables.less';

.shoppingcart-icon {
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: text-bottom;
}

.shoppingcart-icon-edit {
    background: url('images/icons/icon_edit.svg');
}

.shoppingcart-icon-delete {
    background: url('images/icons/icon_delete.svg');
}

input.shoppingcart-icon-delete, input.shoppingcart-icon-delete:hover {
    color: transparent;
}