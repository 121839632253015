﻿@import "../_Variables.less";

.speaker-block {
    background-color: @color-grey3;
    border-radius: 10px;
    margin-top: 15px;
    display: block;

    .person-image {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        height: 150px;
        width: 100%;
        object-fit: cover;

        &.default-image {
            padding: 10px;
            margin: 10px;
            object-fit: contain;
            height: 130px;
            background-color: #fff;
            width: ~"calc(100% - 10px)";
        }
    }

    .speakerblock-content {
        padding: 20px;
        color: @color-darkgrey;
    }

    .speakerblock-arrow {
        height: 150px;
        background: url('Images/icons/plectrum-arrow.svg') 50% no-repeat;
        background-size: 40px;
    }

    &.collapse {
        display: none;

        &.in {
            display: block;
        }
    }

    @media(min-width:@breakpoint-md) {
        .speakerblock-arrow {
            background-size: 60px;
        }
    }
}