﻿@import (reference) '../_Variables.less';
@import (reference) '_mixins.less';

.button, .button:visited, input[type=submit] {
    background-color: @color-green;
    .font-size(@font-size-button-sm);
    color: @color-white;
    border-radius: 3px;
    cursor: pointer;
    padding: 10px 20px;
    outline: none;
    border: 0;
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;

    &.secondary {
        background-color: @color-grey2;
        color: @color-darkgrey;
    }

    &.white {
        background-color: @color-white;
        color: @color-red;
    }

    &.transparent {
        background-color: @color-white;
        color: @color-grey;
        border: 2px solid @color-grey;
    }

    &:hover {
        text-decoration: none;
        background-color: @color-green2;
        color: @color-white;

        &.secondary {
            background-color: @color-grey;
            color: @color-darkgrey;
        }

        &.white {
            background-color: fade(@color-white, 90%);
            color: @color-red;
        }

        &.transparent {
            background-color: @color-white;
            color: @color-grey;
        }
    }

    &.maxwidth {
        width: 100%;
        padding: 10px 0px;
        display: block;
        text-align: center;
    }
}

.courseSubscribeSelection .button {
    font-size: 14px;
}

@media(min-width:@breakpoint-md) {
    .button, .button:visited, input[type=submit] {
        .font-size(@font-size-button-md);
    }
}

@media(min-width:@breakpoint-xl) {
    .button, .button:visited, input[type=submit] {
        .font-size(@font-size-button-xl);
    }
}