﻿@import (reference) '../_Variables.less';
@import (reference) '_mixins.less';

.evaluation {
    margin: 0px -40px;
    width: ~"calc(100% + 80px)";

    .evaluationSectionHeader {
        border-top: 1px solid @color-grey;
        padding: 40px;
    }

    .sectionHeader {
        border-top: 1px solid @color-grey;
        padding: 40px;
        padding-bottom: 0px;

        strong:before {
            content: "";
            display: block;
            background: url("images/icons/comment.svg");
            width: 16px;
            height: 16px;
            position: absolute;
            left: 15px;
            margin-bottom: -30px;
            margin-top: 7px;
        }
    }

    .sectionHeader div, .evaluationSectionHeader div {
        padding-bottom: 20px;
    }

    .sectionHeader p, .evaluationSectionHeader p {
        margin-bottom: 0;
        font-weight: bold;
    }

    .questionoverview {
        margin-left: 40px;
        margin-right: 40px;
    }

    .rangeRow {
        border-bottom: 1px solid @color-grey;
    }

    textarea.textbox {
        background: @color-grey3;
    }

    textarea.twolines {
        height: 100px;
    }

    .counter {
        position: relative;
        top: -150px;
        color: @color-grey;
        .font-size(@font-size-small);
    }

    .questionTitle:not(.full) label {
        font-weight: normal;
    }

    .speakerImage {
        margin: 0px;
        height: auto;
        width: auto;
        background: @color-white;

        .img {
            float: none;
            position: static;
            display: block;
            width: auto;
            height: auto;

            img {
                border-radius: 10px;
                height: auto;
                width: 100%;
                max-width: 250px;                
                top: 0;
                left: 0;
                position: relative;
            }
        }

        .mal {
            display: none;
        }
    }

    .nvtQuestionSwitch {
        font-weight: normal;
        color: @color-red;
        border-radius: 5px;
        .font-size(@font-size-small);

        &.starLabel .helper, &.starLabel:hover .helper {
            background: url("Images/icons/close.svg");
            width: 16px;
            height: 16px;
            margin: 0px 5px;

            &:hover {
                background: url("Images/icons/close.svg");
            }
        }
    }

    label.vinkLabel {
        padding: 0;
        position: relative;

        .txthelper {
            vertical-align: middle;
            padding: 0px 5px;
        }

        .helper, .helper:hover {
            background: none;
            background-image: none !important;
            height: 24px;
            width: 24px;
            border-radius: 12px;
            background-color: @color-grey3;
            border: 1px solid @color-grey-radiobuttons;
        }

        &.vink {
            &:before {
                content: "";
                display: block;
                height: 16px;
                width: 16px;
                border-radius: 10px;
                background-color: @color-red;
                margin-bottom: -20px;
                left: 4px;
                top: 4px;
                position: relative;
            }

            &.yes, &.no {
                color: inherit;
            }
        }

        &.nvt {
            display: none;
        }

        &:hover {
            background: initial;
        }
    }

    .questionoverview.textRow {
        margin-bottom: 0;
    }

    .textRow + .textRow {
        padding-top: 1em;
        border-top: 1px solid @color-grey;
    }

    .starLabel {
        .helper {
            background: url("Images/icons/ster-grijs.svg");
            width: 24px;
            height: 24px;
        }

        &.starred .helper {
            background: url("Images/icons/ster-goud.svg");
        }

        &:hover {
            background: initial;
        }

        &:hover .helper {
            background: url("Images/icons/ster-goud.svg");
        }

        &.nvt:not(.nvtQuestionSwitch) {
            display: none;
        }
    }

    .starHint {
        background: initial;

        &.starred:hover .helper {
            background: url("Images/icons/ster-goud.svg");
        }

        .helper {
            background: url("Images/icons/ster-goud.svg");
        }
    }

    @media(min-width:@breakpoint-md) {

        .sectionHeader strong:before {
            left: 35px;
        }

        .evaluationSectionHeader:before, .sectionHeader:before {
            content: "";
            display: block;
            height: 1px;
            width: 100%;
            margin-left: ~"calc(-50% - 122px)";
            margin-top: -41px;
            margin-bottom: 41px;
            background-color: @color-grey;
        }

        .speakerImage {
            margin-left: -20vw;
        }
    }
}

