﻿@import (reference) '../_Variables.less';

.activefilters {
    margin: 25px -5px;

    .activefilter {
        border-radius: 5px;
        padding: 10px 40px 10px 10px;
        border: 1px solid @color-grey;
        display: inline-block;
        margin: 5px 5px;
        cursor: pointer;
        font-weight: 600;
        background: url("Images/icons/cross.svg") no-repeat;
        background-position: right 14px top 50%;
        background-size: 16px;
        background-color: @color-white;
    }
}

.paging {
    margin: 30px -5px;

    .paging-block {
        border-radius: 5px;
        padding: 10px;
        background-color: @color-white;
        display: inline-block;
        margin: 0px 5px;
        width:50px;
        height: 50px;
        text-align: center;
        cursor: pointer;
        font-weight: bold;

        &.active {
            color: @color-red;
        }
    }
}

.congress-type-image {
    display: inline-block;
    width: 80px;
    height: 60px;
    background-repeat: no-repeat;
    vertical-align: middle;

    &.incompany {
        background-image: url("Images/icons/incompany.svg");
    }

    &.congres {
        background-image: url("Images/icons/congressen.svg");
    }

    &.opleiding {
        background-image: url("Images/icons/opleidingen.svg");
    }

    &.cursus {
        background-image: url("Images/icons/cursussen.svg");
    }

    &.no-image {
        display: none;
    }

    @media(min-width:@breakpoint-md) {
        width: 110px;
        height: 80px;
    }
}

.congress-type-heading {
    display: inline-block;
    vertical-align: middle;
}

.congress-toolate {
    width: 100%;
    border: 3px solid @color-red;
    padding: 10px;
    border-radius: 3px;
}

.emailvalidationmessage.red, .childCourseMessage {
    width: 100%;
    border: 2px solid @color-red;
    padding: 10px;
    border-radius: 3px;
}

.download-button.maxwidth {
    margin-top: 10px;

    @media(min-width:@breakpoint-md) {
        float: right;
        width: auto;
        padding: 10px 20px;
        margin-top: 0px;
    }
}

.congress-loader .congress-loaderimage {
    margin: 100px auto;
    display: block;
}

.accreditationButton .ui-button {
    background-color: @color-green;
}