﻿@import (reference) '../_Variables.less';

.page-block {
    background-color: @color-white;
    border-radius: 10px;
    margin: 30px 0px;

    .page-sidebar {
        padding: 0px;
    }

    .sidebar-content {
        padding: 20px 20px 20px 60px;
    }

    .page-maincontent {
        padding: 40px;
        flex-grow: 1;
        overflow: hidden;

		&.evaluation-page {
			overflow: visible;
		}
    }

    .cms ul li {
        padding-left: .5em;
        list-style-image: url('images/icons/arrow-right.svg') !important;
        margin-bottom: .5em;
        margin-left: 1.5em;
    }

    .cms ul.social-media-share-inline li {
        margin: 0;
        padding: 0;
    }

    .page-image img {
        width: 100%;
        max-height: 400px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        object-fit: cover;
    }

    @media(min-width:@breakpoint-md) {
        .page-image img {
            border-top-right-radius: 0px;
        }

        .page-maincontent {
            padding: 60px;
        }

        .page-sidebar {
            border-right: 1px solid @color-grey;
        }
    }
}

.congressubpageslist, .subpageslist {
    width: 100%;
    float: none;
}

.congressubpageslist .congressmenu .congressmenu-item, .congressmenu .congressmenu-item,
.subpageslist .menu .menu-item, .user-menu .menu .menu-item, .menu .menu-item {
    font-size: inherit;
    display: block;
    border-bottom: 1px solid @color-grey;
    float: none;
    border-right: none;

    a, a:visited, a:hover {
        font-size: inherit;
        font-weight: normal;
        padding: 15px;
        padding-left: 60px;
        display: block;
        color: @color-darkgrey;
        background-color: inherit;
        border: none;
        text-transform: none;
        font-family: inherit;

        &.selMenu, &.active {
            background: url('Images/icons/plectrum-arrow.svg') 15px 50% no-repeat;
            background-size: 30px;
            color: @color-red;
        }
    }
}

.menu .menu-header {
    padding: 40px 15px 15px 60px;
}