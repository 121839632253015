﻿@import "../_Variables.less";

.header-banner {
    margin-top: 160px;
    height: 200px;

    .banner-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        position: absolute;

        .container {
            position: relative;

            .banner-plectrum {
                position: absolute;
                opacity: 0.6;
                z-index: 1;
                margin-left: -100px;
                overflow: hidden;
                height: 200px;
                max-width: ~"calc(100% + 85px)";
                padding-top: 10px;

                img {
                    width: 350px;
                }
            }
        }
    }

    .content {
        padding-top: 10px;
        position: relative;
        z-index: 2;

        .text {
            padding-top: 40px;
            width: 100%;
            max-width: 400px;
            position: relative;
            z-index: 3;

            h2 {
                display: inline;
                color: @color-white
            }

            .text-beige h2 {
                color: @color-beige;
            }
        }
    }

    &.large {
        height: 500px;

        .banner-image {
            height: 500px;

            .banner-plectrum {
                padding-top: 150px;
                height: 500px;

                img {
                    display: block;
                    width: 500px;
                    overflow: hidden;
                    max-width: none;
                }
            }
        }

        .content {
            padding-top: 120px;

            .text {
                padding-top: 80px;
                max-width: 325px;
            }
        }
    }

    @media(min-width:@breakpoint-md) {
        .content .text {
            max-width: 450px;
        }
    }

    @media(min-width:@breakpoint-xl) {
        .content .text {
            max-width: 500px;
        }
    }
}

.has-top-news-item  .header-banner {
    margin-top: 290px;
}