﻿/// <reference path="../_variables.less" />
hr, .hr.hr-default {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}


.ml-button {
    background: @color-secondary;
    color: #fff;
    display: inline-block;
    padding: 1px 10px;
    border: 0;
    line-height: 2;
    box-sizing: border-box;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Arial;
    font-size: 11px;

    &:visited {
        background: @color-secondary;
        color: #fff;
    }

    &:hover {
        background: @color-primary;
        cursor: pointer;
    }
}


.text-button {
    background: none;
    border: 0;
    color: #e31b49;
    cursor: pointer;

    &:hover {
        color: darken(#e31b49,20%);
    }
}

.align-right {
    text-align: right;
}

.text-link {
    &, &:visited {
        color: @color-secondary;
    }

    &:active, &:hover {        
        color: @color-primary;
    }
}

.showpopuplink {
    cursor: pointer;
}

.congresheader a {
    &, &:visited {
        color: #000;
    }
}
