﻿@import (reference) '../_Variables.less';

.breadcrumb-bar {
    border-bottom: 1px solid @color-grey;
    overflow: hidden;

    .container {
        display: flex;
    }


    .breadcrumb-back {
        padding: 20px;
        border-right: 1px solid @color-grey;
        flex-shrink: 0;
    }

    .breadcrumb {
        background-color: initial;
        padding: 20px;
        margin: 0px;
        overflow: hidden;
        flex-shrink: 0;

        span:not(:last-child):after {
            content: "";
            background: url('Images/icons/arrow-right.svg') no-repeat;
            height: 10px;
            width: 10px;
            margin: 0px 10px;
            vertical-align: middle;
            display: inline-block;
        }
    }

    a {
        color: @color-darkgrey;
        text-decoration: underline;
    }

    a:hover {
        border: none;
        color: @color-red;
    }
}