﻿/// <reference path="../_variables.less" />
.subscribeOptionForm {
    font-size: 120%;
    line-height: 1.8;
}

.optieform {
    border: 1px solid #ccc;

    h2 {
        padding-top: 10px;
    }

    iframe {
        margin-bottom: 10px;
    }
}

.label-on-top-form {
    input {
        display: block;
    }

    .salutation {
        .ui-button {
            display: inline-block;
        }

        .required {
            margin-left: 0.33em;
        }
    }

    .checkbox {
        input {
            display: inline-block;
        }

        label {
            display: inline;
        }
    }
}

.takeoptionHelperContainer {
    min-height: 1px;

    &.take-option-enabled {
        * {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        &::before {
            content: ' ';
            display: table;
        }

        @media only screen and (min-width : 960px) {
            margin: 0 -10px;
        }

        > .column {
            padding-bottom: 10px;
        }

        @media only screen and (min-width : 960px) {
            > .column {
                width: 50%;
                float: left;
                padding: 0 10px;
            }
        }
    }
}

.hide-desktop {
    @media only screen and (min-width : 960px) {
        display: none;
    }
}

.take-option-enabled {
    .subscribeNowHeading {
        margin-top: 11px;
    }
}
