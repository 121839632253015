﻿@import (reference) '../_Variables.less';

.user-menu {
	background: @color-grey3;
	font-family: inherit;
	font-size: inherit;
	height: 60px;
	color: @color-darkgrey;
	border-bottom: 1px solid @color-grey;
	width: 100%;
	top: 0px;

	.container {
		padding: 0px;

		.items-left {
			float: left;
			font-weight: 600;
			display: none;

			.item {
				float: left;
				display: block;
				padding: 15px 20px;
			}
		}

		.user-actions {
			float: right;

			.shoppingcart-top-link {
				padding: 15px 30px;
				color: inherit;
				line-height: inherit;

				&:hover {
					background-color: inherit;
				}
			}

			.user-dropdownmenu {
				cursor: pointer;
				padding: 15px 30px;

				&.is-toggled {
					background: inherit;
					color: inherit;
					border: none;
					border-left: 1px solid @color-grey;
					border-right: 1px solid @color-grey;
				}

				.user-menu-toggle {
					top: 61px;
					padding: 0px;
					background-color: @color-grey3;
					border: none;
					position: absolute;
					width: 100%;
					min-width: 300px;
					right: 0px;
					left: auto;
					z-index: 1001;
					max-height: ~"calc(100vh - 60px)";
					overflow-y: auto;

					&.is-toggled {
						display: block;
					}
				}

				.profile-displayname {
					max-width: 380px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					display: inline-block;
					vertical-align: middle;
				}
			}

			.item-block {
				display: block;
				float: left;
				border-left: 1px solid @color-grey;
				margin: 0;
			}

			.item-block:last-child {
				border-right: 1px solid @color-grey;
			}
		}

		.login-button {
			border-radius: 0px;
			display: block;
			float: left;
			font-weight: normal;
			padding: 15px 30px;
			font-size: 16px;
		}
	}

	.check-icon {
		display: inline-block;

		img {
			height: 10px;
			margin-right: 7px;
		}
	}

	.dropdown-icon {
		margin-left: 7px;
		height: 10px;
	}

	.profile-icon {
		margin-right: 7px;
		display: inline-block;

		img {
			height: 22px;
		}
	}

	.cart-icon {
		/*margin-right: 7px;*/
		display: inline-block;
		height: 22px;
		width: 25px;
		background: url("Images/icons/cart.svg");
		background-repeat: no-repeat;
		vertical-align: middle;
	}

	.plectrum-icon {
		position: relative;
		display: block;
		height: 18px;
		width: 21px;
		background-size: 18px;
		background: url("Images/icons/plectrum-small.svg");
		background-repeat: no-repeat;
		color: @color-white;
		line-height: normal;
		vertical-align: middle;
		font-size: 10px;
		text-align: center;
		left: -6px;
		top: 11px;
		padding-right: 2px;
		padding-top: 1px;
	}
}

@media(min-width: @breakpoint-xl) {
	.user-menu .container .items-left {
		display: block;
	}
}