﻿.inline-login-subscribe-container {
    -webkit-transition: height 0.5s ease-in;
    -moz-transition: height 0.5s ease-in;
    -o-transition: height 0.5s ease-in;
    transition: height 0.5s ease-in;
    overflow: hidden;
    position: relative;




    ul {
        list-style-type: none;

        &, > li {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }


        @media only screen and (min-width : 960px) {
            padding-bottom: 25px;
            position: relative;

            small {
                display: block;
            }

            .ml-button {
                position: absolute;
                bottom: 0;
                margin: 0;
            }


            li {
                position: relative;
                padding-bottom: 25px;
                margin: 12px 0;
            }

            small {
                display: block;
            }

            .ml-button {
                position: absolute;
                bottom: 0;
                margin: 0;
            }
        }
    }

    &.take-option-enabled {
        ul {
            @media only screen and (min-width : 960px) {
                .ml-button {
                    right: 0;
                }
            }
        }
    }

    &.take-option-disabled {
        ul {
            @media only screen and (min-width : 960px) {
                &::before {
                    content: ' ';
                    display: table;
                }

                &:after {
                    clear: both;
                    content: ' ';
                    display: table;
                }

                > li {
                    float: left;
                    width: 50%;
                }
            }
        }
    }
}

.inline-login-subscribe {
    background: #F3F4F2;
    padding: 10px;
    padding-top: 1px;
    box-sizing: border-box;



    .ml-button {
        margin: 10px 0;
    }

    margin-bottom: 20px;
}
