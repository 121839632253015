﻿@import (reference) '../_Variables.less';

.footer {
    padding-top: 30px;
    padding-bottom: 30px;

    a, a:active, a:hover {
        color: @color-darkgrey;
    }

    .footer-link-list {
        margin: 10px 0px 20px 0px;
    }

    .social-icons {
        margin: 10px 0px 20px 0px;
        height: 35px;

        img {
            height: 100%;
            margin-right: 10px;
        }
    }

    .footer-question-block {
        border-radius: 10px;
        background-color: @color-white;
    }

    .contact-information-text {
        display: block;
    }

    .question-block-telephone {
        border-top: 1px solid @color-grey;
        padding: 30px;
    }

    .footer-links {
        margin-top: 20px;
    }

    .list-icon {
        margin-right: 7px;

        img {
            height: 10px;
            margin-top: -4px;
        }
    }

    &:after {
        display: none;
    }

    @media (min-width: @breakpoint-md) {
        .contact-information-text {
            display: inline;
        }

        .question-block-telephone {
            border-top: none;
        }
    }
}