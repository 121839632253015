﻿@import (reference) '../_Variables.less';
@import (reference) '_mixins.less';

@font-face {
    font-family: 'Open Sans';
    src: url('/Content/dist/Fonts/OpenSans/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Open Sans';
    src: url('/Content/dist/Fonts/OpenSans/OpenSans-Semibold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/Content/dist/Fonts/OpenSans/OpenSans-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: "Hit the Road";
    src: url('/Content/dist/Fonts/HitTheRoad/HittheRoad.ttf');
}

body, html {
    line-height: 30px;
    font-size: @font-size-main-base;
    font-family: 'Open Sans';
    color: @color-darkgrey
}

.smalltext {
    .font-size(@font-size-small);
}

a {
    color: @color-red;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    &:visited {
        color: @color-red;
    }
}

p {
    margin-bottom: 30px;
}

h1, h2, h3, h4, h5, h6
, .frmobj h3 {
    font-family: "Hit the Road";
    margin: 0;
    color: @color-darkgrey;
}

h1 {
    .font-size(@font-size-h1-sm);
}

h2 {
    .font-size(@font-size-h2-sm);
    word-break: break-word;
}

h3, .frmobj h3 {
    .font-size(@font-size-h3-sm);
}

h4 {
    .font-size(@font-size-h4-sm);
}

h5 {
    .font-size(@font-size-h5-sm);
}

h6 {
    .font-size(@font-size-h6-sm);
}

@media(min-width:@breakpoint-md) {
    h1 {
        .font-size(@font-size-h1-md);
    }

    h2 {
        .font-size(@font-size-h2-md);
        word-break: initial;
    }

    h3 {
        .font-size(@font-size-h3-md);
    }

    h4 {
        .font-size(@font-size-h4-md);
    }

    h5 {
        .font-size(@font-size-h5-md);
    }

    h6 {
        .font-size(@font-size-h6-md);
    }
}

@media(min-width:@breakpoint-xl) {
    h1 {
        .font-size(@font-size-h1-xl);
    }

    h2 {
        .font-size(@font-size-h2-xl);
    }

    h3 {
        .font-size(@font-size-h3-xl);
    }

    h4 {
        .font-size(@font-size-h4-xl);
    }

    h5 {
        .font-size(@font-size-h5-xl);
    }

    h6 {
        .font-size(@font-size-h6-xl);
    }
}