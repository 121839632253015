﻿@import (reference) '_Variables.less';

body {
    background-color: @color-grey3;
}

//TODO: Hack for modernizr+bootstrap
html.hidden {
    display: block !important;
}


.header {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 100;

    @media (max-width: @breakpoint-xl) {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }
}

.section {
    padding: 50px 0px;

    &.dark {
        background-color: @color-grey2;
        border-top: 1px solid @color-grey;
    }
}

.loading-message {
    border-radius: 3px;
    background: none;
    background-color: @color-white;
    border: 1px solid @color-grey;
    padding: 30px;
    text-align: center;
}

@media (max-width: @breakpoint-xl) {
    .container {
        width: auto;
    }
}

/* 5 columns layout with Twitter Bootstrap 3 */
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-5ths {
	width: 20%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px ) {
	.col-md-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-5ths {
		width: 20%;
		float: left;
	}
}

