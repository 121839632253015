﻿/// <reference path="../_Variables" />

.charityChooser a {
	color: @color-primary;
}

.textcolumn .charityChooser ul {
	width: 425px;
}

.charityChooser ul, .charityChooser ul li {
	list-style-type: none;
}

.charityChooser ul .charityRadio {
	margin-left: -20px;
	float: left;
}

.charityLogo {
	float: right;
	margin: 0 0 5px 5px;
}

.questionnvt {
	text-decoration: line-through;
}


form .evaluation select {
	width: auto;
}

form .evaluation textarea {
	background: #fff;
}

form textarea.twolines {
	height: 2.5em;
	max-width: 100%;
}



.evaluation {
	width: 100%;
}

.evaluation th, .evaluation td {
	vertical-align: top;
	padding: 3px 0;
}

.questionNumber {
	width: 7%;
}

.sectionHeader td {
	padding-top: 1em;
}


.rangeRow .questionAnswer, .yesnoRow .questionAnswer, .sliderRow .questionAnswer {
	min-width: 170px;
	text-align: right;
}

.slidercontainer {
	padding: 0px 5px;
}

.range {
	display: none;
}

.rangeRow .boundlabel {
	/*  width:30px;*/
	font-size: 9px;
}

.evaluation .textbox {
	width: 100%;
	/*width: 426px;*/
	/*border: 1px solid #FC7005;*/
}

.evaluation .row td {
	padding: 0.5em 0;
}

.counter {
	text-align: right;
	/*padding-right: 2.5%;*/
}

.counter .error {
	color: red;
}

.questionAnswer table td {
	vertical-align: middle;
}

.boundlabel .ui-button-text-only .ui-button-text {
	padding: 0.5em 0.3em;
	min-width: 1.5em;
}

.dropdownRow .ui-widget {
	font-size: 10px;
}

form label.ui-button {
	margin-right: 0;
}

form input.ui-button {
	padding-top: .45em;
}


#exampleanswer {
	text-align: center;
}

.rangehighlight label:hover .ui-button-text {
	font-weight: bold;
	color: #ff0;
}

label.starLabel.nvt {
	/*margin-right: 2px;*/
	padding-right:5px;
}

.nvt.starLabel.nvtQuestionSwitch {
	color: @color-primary;
}

.sectionHeader .nvt {
	border: 1px solid #ccc;
}

label.starLabel, label.vinkLabel {
	cursor: pointer;
	padding: 2px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

label.nvt {
	padding: 2px;
}

label.starLabel .helper, label.nvt .helper, label.vinkLabel .helper {
	display: inline-block;
	width: 16px;
	height: 16px;
	background: url("/Content/Images/ui-icons_888888_256x240.png") no-repeat -224px -112px;
	line-height: 16px;
	vertical-align: middle;
	text-align: center;
}

label.starLabel.nvt .helper, label.vinkLabel.nvt .helper {
	background: url("/Content/Images/ui-icons_888888_256x240.png") no-repeat -0px -128px;
}

label.starred .helper {
	background: url(@primary-background) no-repeat -224px -112px;
}

label.vink .helper {
	background: url("/Content/Images/ui-icons_cd0a0a_256x240.png") no-repeat -64px -144px;
}

label.starred.nvt .helper, label.vink.nvt .helper {
	background: url("/Content/Images/ui-icons_cd0a0a_256x240.png") no-repeat -0px -128px;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width : 480px) {

	body label.starLabel .helper, body label.nvt .helper, body label.vinkLabel .helper {
		display: inline-block;
		width: 24px;
		height: 24px;
		background: url("/Content/Images/ui-icons_888888_384x360.png") no-repeat -336px -168px;
		line-height: 24px;
		vertical-align: middle;
		text-align: center;
	}

	body label.starLabel.nvt .helper, body label.vinkLabel.nvt .helper {
		background: url("/Content/Images/ui-icons_888888_384x360.png") no-repeat -0px -192px;
	}

	body label.starred .helper {
		background: url("/Content/Images/ui-icons_cd0a0a_384x360.png") no-repeat -336px -168px;
	}

	body label.vink .helper {
		background: url("/Content/Images/ui-icons_cd0a0a_384x360.png") no-repeat -96px -216px;
	}

	body label.starred.nvt .helper, label.vink.nvt .helper {
		background: url("/Content/Images/ui-icons_cd0a0a_384x360.png") no-repeat -0px -192px;
	}


	body label.starLabel.nvt:hover .helper, body label.vinkLabel.nvt:hover .helper {
		background: url("/Content/Images/ui-icons_e2001a_384x360.png") no-repeat -0px -192px;
	}

	body label.starHint .helper {
		background: url("/Content/Images/ui-icons_6292a0_384x360.png") no-repeat -336px -168px;
	}

	body label.starLabel:hover .helper {
		background: url("/Content/Images/ui-icons_e2001a_384x360.png") no-repeat -336px -168px;
	}


	body label.starred.starHint:hover .helper {
		background: url("/Content/Images/ui-icons_e2001a_384x360.png") no-repeat -336px -168px;
	}



	body label.vinkLabel.yes .helper {
		background: url("/Content/Images/ui-icons_888888_384x360.png") no-repeat -96px -216px;
	}

	body label.vinkLabel.no .helper {
		background: url("/Content/Images/ui-icons_888888_384x360.png") no-repeat -120px -192px;
	}

	body label.vinkLabel.no:hover .helper {
		background-image: url("/Content/Images/ui-icons_e2001a_384x360.png");
	}

	body label.vinkLabel.vink .helper {
		background-image: url("/Content/Images/ui-icons_cd0a0a_384x360.png");
	}

	body label.vinkLabel.yes:hover .helper {
		background-image: url("/Content/Images/ui-icons_06b800_384x360.png");
	}

	body label.vinkLabel.vink.yes .helper {
		background-image: url("/Content/Images/ui-icons_06b800_384x360.png");
	}

	body label .txthelper {
		height: 24px;
		vertical-align: bottom;
		padding-left: 1px;
		line-height: 24px;
		display: inline-block;
	}
}

label.nvt:hover {
	background: #ddd;
}

@primary-background: %("/Content/Images/ui-icons_%d_256x240.png",replace(@color-primary, "#",""));
@secondary-background: %("/Content/Images/ui-icons_%d_256x240.png",replace(@color-secondary, "#",""));

label.starLabel.nvt:hover .helper, label.vinkLabel.nvt:hover .helper {
	background: url("/Content/Images/ui-icons_e2001a_256x240.png") no-repeat -0px -128px;
}

label.starHint .helper {
	background: url(@secondary-background) no-repeat -224px -112px;
}

label.starLabel:hover .helper {
	background: url(@primary-background) no-repeat -224px -112px;
}

label.starHint {
	background: #ddd;
}

label.starred.starHint:hover .helper {
	background: url(@primary-background) no-repeat -224px -112px;
}

.evaluation .hidden {
	display: none;
}

label .txthelper {
	height: 16px;
	vertical-align: bottom;
	padding-left: 1px;
	line-height: 16px;
	display: inline-block;
}

label.firstHeader .txthelper {
	padding: 0;
	padding-right: 1px;
}

label.starred .txthelper {
	color: #cd0a0a;
}

label.vinkLabel .txthelper.hidden, label.nvt .txthelper {
	display: none;
}


label.vinkLabel.yes .helper {
	background: url("/Content/Images/ui-icons_888888_256x240.png") no-repeat -64px -144px;
}

label.vinkLabel.no .helper {
	background: url("/Content/Images/ui-icons_888888_256x240.png") no-repeat -96px -128px;
}

label.vinkLabel.no:hover .helper {
	background-image: url("/Content/Images/ui-icons_e2001a_256x240.png");
}

label.vinkLabel.vink .helper {
	background-image: url("/Content/Images/ui-icons_cd0a0a_256x240.png");
}

label.vinkLabel.yes:hover .helper {
	background-image: url("/Content/Images/ui-icons_06b800_256x240.png");
}

label.vinkLabel.vink.yes .helper {
	background-image: url("/Content/Images/ui-icons_06b800_256x240.png");
}

label.vinkLabel.vink.yes {
	color: #06b800;
}

label.vinkLabel.vink.no {
	color: #e31b49;
}



label.vinkLabel:hover, label.starLabel:hover {
	background: #ddd;
}

/* IE9 is also a bit odd */


.evaluationcontact .hiddenContactMe .text-box {
	border: 1px solid #FC7005;
	padding: 3px 5px;
}



.validation-summary-valid {
	display: none;
}

.validation-summary-errors {
	color: red;
	padding-bottom: 20px;
}

.validation-summary-errors a {
	color: #c0001a; /*   text-decoration: underline;*/
}

.validation-summary-errors ul {
	display: none;
}



/* new */
.evaluation {
	@default-margin-bottom: 1em;

	.sectionHeader, .questionoverview {
		margin-bottom: @default-margin-bottom;
	}

	.nvtQuestionSwitchLabel {
		margin-bottom: @default-margin-bottom;
	}

	* {
		box-sizing: border-box;
	}

	.questionTitle {
		line-height: 2em;
		&.full {
			line-height:2.5em;
		}
	}

	@media only screen and (min-width:480px) {
		.split {
			display: flex;
			flex-direction: row;
			align-items: stretch;

			.questionTitle {
				flex-grow: 1;
			}

			.questionAnswer {
				text-align: right;
				flex-grow: 1;
			}
		}
	}

	.speakerImage {
		width: 101px;
		position: relative;
		height: 83px;

		@media only screen and (min-width:768px) {
			position: absolute;
			margin-left: -139px;
			float: left;
			width: 133px;
			height: auto;
			margin-top: -7px;
		}
	}
}
