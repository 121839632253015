﻿.row {
    &:after {
        content: " ";
        display: table;
    }

    .col-label {
        width: 20%;
        float: left;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
    }

    .col-offset-label {
        margin-left: 20%;
    }
}

.row-container-960 {
    box-sizing: border-box;
    * {
         box-sizing: border-box;
    }

    margin: 0 -10px;

        .col-100,.col-50 {
            width: 100%;
            padding: 0 10px;
        }

    @media only screen and (min-width : 960px) {
        

        * {
            box-sizing: border-box;
        }

        &:after {
            content: " ";
            display: table;
        }

        .col-50 {
            float: left;
            width: 50%;
        }
    }
}

.row-container {
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }

    &:after {
        content: " ";
        display: table;
    }

    margin: 0 -10px;

    .col-100 {
        width: 100%;
        padding: 0 10px;
    }

    .col-50 {
        float: left;
        width: 50%;
        padding: 0 10px;
    }
}
