﻿@import (reference) '../_Variables.less';

.newsletter-bar {
    width: 100%;
    min-height: 100px;
    background-color: @color-darkgrey;

    .newsletter-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: auto;
        min-height: 100px;
        justify-content: space-between;
        margin: 20px 0;
    }

    .newsletter-subscription-text {
        color: @color-white;
        width: 100%;
        display: block;
        margin: 0px 0px 10px 0px;
    }
}

@media(min-width:@breakpoint-md) {
    .newsletter-bar {
        .newsletter-container {
            height: 100%;
            margin: 0px;
        }

        .newsletter-subscription-text {
            width: auto;
            margin: 0;
        }
    }
}

.newsletterbar-top {
    margin-top: 160px;

    + .header-banner {
        margin-top: 0;
    }    
}

.has-top-news-item {
    .newsletterbar-top {
        margin-top: 290px;

        + .header-banner {
            margin-top: 0;
        }
    }
}
