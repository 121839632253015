﻿@import (reference) '../_Variables.less';

.top-news-item {
    background-color: @color-white;
    padding: 20px 0px;
    min-height: 130px;

    p {
        margin: 0;
        display: inline;
    }

    .top-news-item-inner {
        display: flex;
        position: relative;
        align-items: center;

        .newsitem {
            max-width: ~"calc(100% - 32px)";
        }
    }

    .close {
        position: absolute;
        right: 0px;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    .close:hover {
        opacity: 1;
    }

    .close:before, .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
    }

    .close:before {
        transform: rotate(45deg);
    }

    .close:after {
        transform: rotate(-45deg);
    }
}

.top-news-heading {
    color: @color-red;
    font-weight: 700;
    font-size: 1.2rem;
    display: block;
}