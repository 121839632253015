﻿.emailExplainPopupLink span {
	width: 16px;
	height: 16px;
	background-image: url("/Content/dist/images/ui-icons_6292a0_256x240.png");
	background-position: -16px -144px;
	background-repeat: no-repeat;
	display: inline-block;
}

.emailvalidationmessage {
	font-weight: bold;

	&.red {
		border: 1px solid #E2001A;
		padding: 5px;
	}

	&.red a {
		color: #E2001A;
	}

	span {
		font-size: 120%;
		color: @color-primary;
	}

	a {
		color: @color-secondary;
	}
}

.autocompleteDropdown {
	position: relative;
	display: inline-block;
}

.autocompleteDropdown-toggle {
	position: absolute;
	top: 0;
	bottom: 0;
	margin-left: -1px;
	padding: 0;
	background: none;
	border: 0;
	right: 0;
	margin-right: -0.2em;
}

#DiscountId-button {
	min-width:200px;
	width: auto !important;
}

.subscribeFormElement {
	// Nested list-items
	ul ul {
		padding-left: 30px;
	}

	li > span.constraint {
		padding-left: 30px;
	}

	.congresspart-workshop-selector {
		label {
			max-width: ~'calc(100% - 30px)';
		}
	}
}

.congresspart-workshop-selector {
	> li > ul {
		> li {
			list-style-type: none;

			> .workshopcheckbox, > .fully-booked {
				margin-left: -2.15em;
				float: left;
			}

			> .workshopcheckbox {
				margin-top: 4px;
				margin-left: -2em;
			}
		}
	}
}

.courseSubscribeSelection {
    &, li {
        list-style-type:none;

    }

    li {
        padding-left:0;
        margin-left:0;
		margin-bottom: 2em;
		&:last-child {
		  margin-bottom: 0;
		}
        span {
            font-style:italic;
        }
    }
}

form {
	.formfield.has-alergies-field {
		min-height: unset;
		margin-top: 40px;
	}

	.dietary-fields {
		.label {
			margin-right: 20px;
		}
	}
}
