/// <reference path="_Variables.less" />

body {
	background: white url("@{images_path}bg.png") repeat-y center top;
	font: 62.5%/1.4 "Helvetica", Arial;
	min-height: 100%;
}

html {
	min-height: 100%;
	height: auto;
}

#center {
	width: 899px;
	margin: 0 auto;
	padding-left: 30px;
	padding-top: 45px;
	font-size: 120%;
}

a {
	color: #1A171B;
	text-decoration: none;
}

a:visited {
	color: #1A171B;
}

#brancheselector {
	float: right;
	padding-right: 26px;
	width: 379px;
	position: relative;
}

#brancheselector p {
	float: left;
	width: 180px;
	padding-top: 3px;
}

#brancheselector ul {
	width: 235px;
	left: 180px;
	top: 0;
	position: absolute;
	height: 30px;
	background: url("@{images_path}brancheselector.png") no-repeat top left;
	cursor: pointer;
}

#brancheselector ul li {
	width: 206px;
	padding: 3px 5px;
	display: none;
	background: url("@{images_path}selectorbranchebg.png") no-repeat center center;
}

#brancheselector ul li:first-child {
	background: url("@{images_path}selectorbranchebg.png") no-repeat top center;
}

#brancheselector ul li:last-child, #brancheselector ul li.lastchild {
	background: url("@{images_path}selectorbranchebg.png") no-repeat bottom center;
	padding-bottom: 28px;
}

#brancheselector ul.clicked li {
	display: list-item;
}

strong.required {
	font-weight: bold;
	color: #dd0000;
}

/* header */

.menu {
	margin-top: 18px;
}

.menu .top {
	padding-left: 6px;
}

.menu li {
	float: left;
	box-sizing:border-box;
	border-right:1px solid #fff;
}

.menu li a {
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 7px;
	padding-bottom: 7px;
	display: block;
	color: @color-primary;
	font-weight: bold;
	font-size: 140%;
	text-transform: lowercase;
}

.menu li a strong {
	text-transform: none;
}

.menu li.active a {
	background: #fff;
	color: #1A171B;
}

.menu li a:hover {
	background: #fff;
	color: #1A171B;
}

.bigcolumn {
	background: url("@{images_path}bigcolbottom.png") no-repeat bottom left;
	padding-bottom: 12px;
}

.bigcolumn .top {
	background: url("@{images_path}bigcoltop.png") no-repeat top left;
	padding-top: 10px;
}

#brancheselector {
	position: relative;
	z-index: 100;
}

.teaserheader {
	position: relative;
	z-index: 50;
}

/* columns */

.contentcolumns {
	background: url("@{images_path}columnsheader.png") no-repeat top left;
	margin-top: 15px;
}

/* textcolumn */

.textcolumn {
	width: 626px;
	float: left;
	background: url("@{images_path}textcolumnbottom.png") no-repeat bottom left;
	margin-top: 27px;
	padding-bottom: 12px;
}

.textcolumn li {
	padding-left: 0.5em;
	list-style-type: disc;
	margin-bottom: 0.5em;
	margin-left: 2em;
}

.textcolumn .columnbottom {
	background: url("@{images_path}textcolumn.png") repeat;
	padding-right: 20px;
	padding-left: 20px;
}

.textcolumn h1 {
	color: @color-secondary;
	font: 160% Georgia;
	margin-bottom: 1em;
}

.frmobj h3 {
	color: @color-secondary;
	font: 130% Georgia;
	margin-bottom: 1em;
}

.subpageslist {
	float: left;
	width: 139px;
}

.congressubpages .congrestextcontent, .subpages .textcolumn {
	float: left;
	width: 400px;
    padding-top:10px;
}

.congressubpageslist {
	float: left;
	width: 139px;
}

.congressubpages .congressubpageslist ul, .subpages .subpageslist ul, .subpages .subpageslist ol {
	width: auto;
	color: @color-secondary;
}

.congressubpages .congressubpageslist a, .subpages .subpageslist a {
	color: @color-secondary;
	font-weight: bold;
	font-size: 125%;
}

.subpages .subpageslist a.selMenu, .congressubpages .congressubpageslist a.selMenu {
	color: #000;
}

.congressubpages .congressubpageslist a:hover {
	color: #000;
}

.congressubpages .congressubpageslist ul li, .subpages .subpageslist ol li, .subpages .subpageslist ul li {
	padding: 0;
	margin-left: 0px;
	font-size: 75%;
	list-style-position: inside;
}

.subpages .textcontent {
	float: left;
	width: 441px;
}

.mapobj {
	height: 400px;
}

#map .gtext p {
	margin: 0;
	width: auto;
}

strong {
	font-weight: bold;
}

.cms a {
	color: @color-primary;
}

.cms a:hover {
	color: @color-secondary;
}

.cms h3 {
    font-weight: bold;
}

.cms h4, h2 {
	color: @color-secondary;
	font: 140% Georgia;
	margin-bottom: 1em;
}

.cms h5 {
	color: @color-primary;
	font-size: 120%;
	margin-bottom: 1em;
	font-weight: bold;
}

.cms h6 {
	color: @color-primary;
	font-size: 120%;
	margin-bottom: 1em;
}

div div .cms p, div div .cms ul {
	width: auto;
}

#map .gtext {
	font-family: Helvetica, Arial;
	line-height: 14px;
	padding: 3px;
	font-size: 14px;
}

.textcolumn p, .textcolumn ul {
	width: 400px;
	margin: 1em 0;
}

.textcolumn table ul {
	width: auto;
	margin: 0;
}

.textcolumn .nextcongres p, .textcolumn .medewerkershort p {
	margin: 0;
}

.nextcongres .datum, .medewerkershort .datum {
	font: 120% Georgia,serif;
	padding-top: 5px;
	vertical-align: text-bottom;
	width: auto;
}

.nextcongres .datum .nadruk, .medewerkershort .datum .nadruk {
	font: 90% Helvetica, Sans-Serif;
	font-weight: bold;
	vertical-align: text-bottom;
}

.nextcongresses, .medewerkershort {
	margin-top: 20px;
}

.textcontent h2, .textcontent h3, .textcontent h4 {
	color: @color-secondary;
	font: 160% Georgia;
	margin-top: -3px;
	margin-bottom: 1em;
}

.nextcongres {
	position: relative;
}

.nextcongres h3, .medewerkershort h3, .nextcongres h1 {
	font-size: 200%;
	font-weight: bold;
	font-family: Helvetica,Arial;
	line-height: 1.1;
	margin-bottom: 0.2em;
	margin-top: 0.3em;
}

.nextcongres h3 a, .medewerkershort h3 a {
	color: #000;
}

.relatedtopimage {
	margin-top: -72px;
	float: left;
}

#twitter .twitUser, #twitter .twitHeader {
	display: none;
}

#twitter .twitEntry {
	margin: 0;
	padding-bottom: 0.5em;
}

#twitter.twit {
	background-color: transparant;
	padding: 0;
}

.relatedcolumn h3 a strong {
	color: @color-primary;
}

.relatedcolumn #twitter a:hover {
	border: 0;
	color: @color-primary;
}

.relatedcolumn {
	width: 265px;
	float: left;
	margin-left: -18px;
	margin-top: 27px;
}

.relatedcolumn li {
	min-height: 20px;
}

.relatedcolumn a:hover {
	color: @color-primary;
}

.relatedbox .box {
	background: url("@{images_path}relatedcolumn.png") no-repeat bottom left;
	padding-bottom: 12px;
	padding-left: 14px;
	padding-top: 5px;
	padding-right: 14px;
}

.relatedbox h3 {
	background: url("@{images_path}relatedboxheader.png") no-repeat top left;
	padding-left: 14px;
	font-weight: bold;
	font-size: 120%;
	margin-top: -8px;
	padding-top: 8px;
	text-transform: uppercase;
}

.relatedbox h3.topbox {
	background: none;
	background: url("@{images_path}relatedcolumn.png") no-repeat top left;
	padding-top: 0;
	margin-top: 0;
	height: 9px;
}

.relatedbox .box {
	padding-bottom: 1.5em;
}

.relatedbox h3.topbox span {
	position: relative;
	top: -8px;
}

span.icon {
	display: inline-block;
	width: 17px;
	height: 16px;
	margin-right: 5px;
	background: url("@{images_path}icons.png") no-repeat;
	vertical-align: text-bottom;
}

html.backgroundsize span.icon {
	background-image: url("@{images_path}icons@2x.png");
	-moz-background-size: 162px 48px;
	-ie-background-size: 162px 48px;
	-o-background-size: 162px 48px;
	-webkit-background-size: 162px 48px;
	background-size: 162px 48px;
}

.congressdownloads li a {
	color: #000;
}

.congressdownloads li a:hover {
	color: @color-primary;
}

span.icon.icon-twitter {
	background-position: 0 0;
}

a:hover span.icon.icon-twitter {
	background-position: 0 -16px;
}

span.icon.icon-facebook {
	background-position: -17px 0;
}

a:hover span.icon.icon-facebook {
	background-position: -17px -16px;
}

span.icon.icon-linkedin {
	background-position: -36px 0;
}

a:hover span.icon.icon-linkedin {
	background-position: -36px -16px;
}

span.icon.icon-send-a-friend {
	background-position: -55px 0;
}

a:hover span.icon-send-a-friend {
	background-position: -55px -16px;
}

.congressdownloads span.icon {
	background-position: -90px 0;
}

.congressdownloads a:hover span.icon {
	background-position: -90px -16px;
}

.relatedcongresses span.icon {
	background-position: -72px 0;
}

.relatedcongresses a:hover span.icon {
	background-position: -72px -16px;
}

span.icon-shoppingcart {
	background-position: -126px 0;
}

a:hover span.icon-shoppingcart {
	background-position: -126px -16px;
}

span.icon-ask {
	background-position: -108px 0;
}

a:hover span.icon-ask {
	background-position: -108px -16px;
}

span.icon-news,
span.icon-news {
	background-position: -144px 0;
}

a:hover span.icon-news,
a:hover span.icon-faq {
	background-position: -144px -16px;
}

.voorwaarden {
	color: #333333;
}

.newsteaser {
	font-weight: bold;
}

/*send a friend*/

.ui-dialog div.sendafriendform {
	margin: 0.5em 0.5em;
	overflow: hidden;
}

.sendafriendform p {
	margin-bottom: 0.5em;
}

.sendafriendform a {
	color: @color-secondary;
}

#messages {
	display: none;
	position: absolute;
	left: 50%;
	top: 500px;
	margin-left: -125px;
	width: 250px;
	padding: 10px;
	font-weight: bold;
	font-size: 120%;
}

#messages .ui-icon {
	float: left;
	margin-right: 0.5em;
}

.shoppingcart {
	max-width: 100%;
}

.shoppingcart tfoot tr th, .shoppingcart tfoot tr td {
	padding: 3px;
}

.shoppingcart thead tr th {
	border-bottom: 1px solid #ccc;
	padding: 3px;
}

.shoppingcart tbody tr td {
	border-bottom: 1px solid #e0e0e0;
	vertical-align: top;
	padding: 3px;
}

table .currency {
	text-align: right;
}

.shoppingcart {
	margin-bottom: 1em;
}

.external .info {
	display: none;
}

.shoppingcart .icon-pull-left {
    float: left;
}

/* markers */

.marker li em {
	font-style: italic;
}

.marker p em {
	font-style: italic;
}

.lineEndBreak {
	position: absolute;
	left: 0;
	top: 0;
	background: url("@{images_path}marker.png") repeat-y top right;
}

.lineEndBreak span {
	padding-left: 2px;
}

.lineStartBreak {
	position: absolute;
	left: 0;
	top: 0;
	background: url("@{images_path}marker.png") repeat-y left bottom;
	overflow: hidden;
}

.lineStartBreak span {
	padding-left: 2px;
}

.lineStart {
	background: url("@{images_path}marker.png") repeat-y left bottom;
	padding-left: 2px;
}

.lineEnd {
	background: url("@{images_path}marker.png") repeat-y right top;
	padding-left: 2px;
}

/* teaser */

.teaserheader {
	margin-left: 6px;
}

.teaserimg {
	float: left;
	position: relative;
	width: 612px;
	height: 177px;
	background: url("@{images_path}teaserimgshadow.png") no-repeat bottom right;
}

.teaserimg img {
	position: absolute;
	top: 0;
	left: 0;
	width: 600px;
	height: 165px;
}

.teaserreference {
	position: absolute;
	top: 70px;
	left: 0;
	color: @color-secondary;
	font: 160% Georgia;
	font-style: italic;

	p {
		margin-top: 5px;
		margin-bottom: 10px;

		span.teaserrow {
			background: url("@{images_path}teasertextbg.png") no-repeat top right;
			padding-right: 10px;
			padding-top: 3px;
			padding-bottom: 3px;
			padding-left: 5px;
			height: 33px;
			display: inline-block;
		}

		span.who {
			font-size: 80%;
			padding: 0;
		}
	}
}


.teasertext {
	float: left;
	width: 206px;
	height: 164px;
	padding-left: 35px;
	padding-top: 13px;
	padding-right: 32px;
	font-size: 120%;
	line-height: 1.25;
}

/* breadcrumb */

.breadcrumb {
	margin-top: -10px;
}

.breadcrumb .top {
	padding-left: 6px;
	padding-right: 35px;
	padding-top: 10px;
}

.breadcrumb .top div {
	background: #fff;
	padding-top: 2px;
	padding-left: 7px;
	padding-bottom: 2px;
}

.breadcrumb a {
	color: #000;
	border-bottom: 1px solid #fff;
}

.breadcrumb a:hover {
	color: #333;
	border-bottom: 1px solid #333;
}

/* standaardbox */

.textcolumn .box {
	background: #F3F4F2;
	width: 583px;
	margin-bottom: 5px;
}

.textcolumn .box div:first-child {
	background: url("@{images_path}boxtopleft.png") no-repeat top left;
}

.textcolumn .box .branches h4 {
	margin-left: 30px;
	font-size: 110%;
	font-weight: bold;
	text-transform: uppercase;
	padding-top: 1em;
}

.textcolumn .box .branches li {
	width: 40%;
	float: left;
	font-weight: bold;
	color: @color-primary;
}

.textcolumn .box .branches li a {
	color: @color-primary;
}

.cms a {
	color: @color-primary;
	&.button{
		color:@color-white;
	}
}

.cms a:hover {
	color: @color-secondary;
	&.button{
		color: @color-white;
	}
}

/* homepage */

.nextcongres, .medewerkershort {
	background: #F3F4F2;
	width: 583px;
	margin-bottom: 5px;
}

div .medewerkershort {
	width: 431px;
}

.selectable .nextcongres:hover, .medewerkershort:hover {
	color: @color-secondary;
}

.selectable .nextcongres:hover a, .medewerkershort:hover a {
	color: @color-primary;
}

.nextcongres:hover .nadruk, .medewerkershort:hover .nadruk, .notselectable .nextcongres:hover a {
	color: #000;
}

.nextcongres .lineStartBreak span, .nextcongres .lineEndBreak span, .nextcongres .lineEnd, .nextcongres .lineStart, .medewerkershort .lineStartBreak span, .medewerkershort .lineEndBreak span, .medewerkershort .lineEnd, .nextcongres .lineStart {
	background-image: url("@{images_path}markergrey.png");
}

.nextcongres .img, .medewerkershort .img {
	width: 130px;
	height: 111px;
	position: relative;
	float: left;
}

.nextcongres .img .mal, .medewerkershort .img .mal {
	width: 127px;
	height: 111px;
	background: url("@{images_path}congresimgmal.png") no-repeat top left;
	position: absolute;
	top: 0;
	left: 0;
}

.nextcongres .img img, .medewerkershort .img img {
	position: absolute;
	left: 6px;
	top: 9px;
}

.userteaser {
	width: 443px;
	float: left;
	padding-left: 10px;
}

.congresteaser {
	padding-left: 10px;
	float: left !important;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 60% !important;
	width: calc(~"100% - 105px") !important;
}

@media only screen and (min-width: 700px) {
	.congresteaser {
		width: 80% !important;
		width: calc(~"100% - 135px") !important;
		margin-left: 0 !important;
	}
}

@media only screen and (min-width : 1000px) {
	.congresheader .congresteaser {
		padding-left: 8%;
	}
}

div .userteaser {
	width: 291px;
}

.employeeimage {
	width: 200px;
	float: right;
	margin-left: 10px;
	margin-bottom: 10px;
	height: 200px;
}

.employeeimage.switcher .photo2 {
	display: none;
}

.employeeimage.switcher:hover .photo1 {
	display: none;
}

.employeeimage.switcher:hover .photo2 {
	display: block;
}

/* forms */

form .editor-field {
	float: left;
	width: 250px;
}

form .editor-label {
	clear: left;
	float: left;
	width: 150px;
}

form h3 {
	clear: left;
}

form .formfield {
	margin: 0.5em 0;

	&.country-of-birth {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 0;
		width: 0;
		z-index: -1;
	}
}

form .text-box.single-line {
	width: 190px;
	height: 14px;
	padding: 3px 5px;
	background: url("@{images_path}textbox.png") no-repeat top center;
	border: 0;
}

form textarea {
	background: url("@{images_path}textbox.png") no-repeat bottom center;
	height: 94px;
	padding: 3px 5px;
	width: 190px;
	border: 0;
	resize: none;
}

form .congresgegevens li, form .accreditaties li {
	list-style-type: none;
}

form .congresgegevens li input {
	margin-left: -22px;
	margin-top: 3px;
	float: left;
}

form select {
	width: 200px;
}

.accreditaties li {
	margin: 0;
	padding: 0;
}

.accreditaties .ui-state-default .ui-icon-check {
	background-position: -2064px -144px;
}

.accreditaties .ui-state-active .ui-icon-check {
	background-position: -64px -144px;
}

form .field-validation-error {
	display: block;
	width: 200px;
}

form .constraint.error, form .field-validation-error {
	color: #ff0000;
}

table {
	width: 100%;
}

th {
	font-weight: bold;
}

.opdehoogte ul li {
	list-style-type: none;
}

.checkboxes input {
	float: left;
	margin-left: -20px;
}

/* Selectmenu
----------------------------------*/
.ui-selectmenu {
	display: block;
	display: inline-block;
	position: relative;
	height: 2.2em;
	vertical-align: middle;
	text-decoration: none;
	overflow: hidden;
	zoom: 1;
}

.ui-selectmenu-icon {
	position: absolute;
	right: 6px;
	margin-top: -8px;
	top: 50%;
}

.ui-selectmenu-menu {
	padding: 0;
	margin: 0;
	position: absolute;
	top: 0;
	display: none;
	z-index: 1005;
}
/* z-index: 1005 to make selectmenu work with dialog */
.ui-selectmenu-menu ul {
	padding: 0;
	margin: 0;
	list-style: none;
	position: relative;
	overflow: auto;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}

.ui-selectmenu-open {
	display: block;
}

ul.ui-selectmenu-menu-popup {
	margin-top: -1px;
}

.ui-selectmenu-menu li {
	padding: 0;
	margin: 0;
	display: block;
	border-top: 1px dotted transparent;
	border-bottom: 1px dotted transparent;
	border-right-width: 0 !important;
	border-left-width: 0 !important;
}

.ui-selectmenu-menu li a, .ui-selectmenu-status {
	line-height: 1.4em;
	display: block;
	padding: .405em 2.1em .405em 1em;
	outline: none;
	text-decoration: none;
}

.ui-selectmenu-menu li.ui-state-disabled a, .ui-state-disabled {
	cursor: default;
}

.ui-selectmenu-menu li.ui-selectmenu-hasIcon a,
.ui-selectmenu-hasIcon .ui-selectmenu-status {
	padding-left: 20px;
	position: relative;
	margin-left: 5px;
}

.ui-selectmenu-menu li .ui-icon, .ui-selectmenu-status .ui-icon {
	position: absolute;
	top: 1em;
	margin-top: -8px;
	left: 0;
}

.ui-selectmenu-status {
	line-height: 1.4em;
}

.ui-selectmenu-menu li span, .ui-selectmenu-status span {
	display: block;
	margin-bottom: .2em;
}

.ui-selectmenu-menu li a > span {
	display: inline-block;
}


.domainListMenu li span, .domainListButton span span {
	display: inline-block;
}

.domainListButton span span {
	font-size: 0.9em;
}

.domainListMenu .selectmencol, .domainListButton .selectmencol {
	overflow: hidden;
	-ms-text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.ui-menu.domainListMenu {

	.ui-menu-item {
      
		padding: 3px;
		border:0;
		margin: 0;

		&:first-child {
			display: none;
		}

		&.ui-state-disabled {
			opacity: 1;
		}
	}
}

.domainListMenu .col1, .domainListButton .col1 {
	width: 40%;
}

.domainListMenu .col2, .domainListButton .col2 {
	width: 30%;
}

.domainListMenu .col3, .domainListButton .col3 {
	width: 30%;
}

.ui-selectmenu-menu li .ui-selectmenu-item-header {
	font-weight: bold;
}

.ui-selectmenu-menu li .ui-selectmenu-item-footer {
	opacity: .8;
}

.ui-selectmenu-menu .ui-menu .ui-state-focus {
	margin: 0;
}

.ui-selectmenu-menu .ui-menu {
	padding-bottom: 0 !important;
}

.ui-selectmenu-button {
	&.ui-corner-top {
		background-image: url("../../Content/dist/images/ui-bg_gloss-wave_65_6292a0_500x100.png") !important;
		color: #fff;

		.ui-icon {
			background-image: url("../../Content/dist/images/ui-icons_ffffff_256x240.png");
		}
	}
}


/* for optgroups */
.ui-selectmenu-menu .ui-selectmenu-group {
	font-size: 1em;
}

.ui-selectmenu-menu .ui-selectmenu-group .ui-selectmenu-group-label {
	line-height: 1.4em;
	display: block;
	padding: .6em .5em 0;
	font-weight: bold;
}

.ui-selectmenu-menu .ui-selectmenu-group ul {
	margin: 0;
	padding: 0;
}
/* IE6 workaround (dotted transparent borders) */
* html .ui-selectmenu-menu li {
	border-color: pink;
	filter: chroma(color=pink);
	width: 100%;
}

* html .ui-selectmenu-menu li a {
	position: relative;
}
/* IE7 workaround (opacity disabled) */
* + html .ui-state-disabled, * + html .ui-state-disabled a {
	color: silver;
}
/*
div.light_rounded .pp_top .pp_left {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -88px -53px no-repeat;
}

div.light_rounded .pp_top .pp_middle {
	background: #fff;
}

div.light_rounded .pp_top .pp_right {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -110px -53px no-repeat;
}

div.light_rounded .pp_content .ppt {
	color: #000;
}

div.light_rounded .pp_content_container .pp_left, div.light_rounded .pp_content_container .pp_right {
	background: #fff;
}

div.light_rounded .pp_content {
	background-color: #fff;
}

div.light_rounded .pp_next:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/btnNext.png) center right no-repeat;
	cursor: pointer;
}

div.light_rounded .pp_previous:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/btnPrevious.png) center left no-repeat;
	cursor: pointer;
}

div.light_rounded .pp_expand {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -31px -26px no-repeat;
	cursor: pointer;
}

div.light_rounded .pp_expand:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -31px -47px no-repeat;
	cursor: pointer;
}

div.light_rounded .pp_contract {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) 0 -26px no-repeat;
	cursor: pointer;
}

div.light_rounded .pp_contract:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) 0 -47px no-repeat;
	cursor: pointer;
}

div.light_rounded .pp_close {
	width: 75px;
	height: 22px;
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -1px -1px no-repeat;
	cursor: pointer;
}

div.light_rounded #pp_full_res .pp_inline {
	color: #000;
}

div.light_rounded .pp_gallery a.pp_arrow_previous, div.light_rounded .pp_gallery a.pp_arrow_next {
	margin-top: 12px !important;
}

div.light_rounded .pp_nav .pp_play {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -1px -100px no-repeat;
	height: 15px;
	width: 14px;
}

div.light_rounded .pp_nav .pp_pause {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -24px -100px no-repeat;
	height: 15px;
	width: 14px;
}

div.light_rounded .pp_arrow_previous {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) 0 -71px no-repeat;
}

div.light_rounded .pp_arrow_previous.disabled {
	background-position: 0 -87px;
	cursor: default;
}

div.light_rounded .pp_arrow_next {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -22px -71px no-repeat;
}

div.light_rounded .pp_arrow_next.disabled {
	background-position: -22px -87px;
	cursor: default;
}

div.light_rounded .pp_bottom .pp_left {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -88px -80px no-repeat;
}

div.light_rounded .pp_bottom .pp_middle {
	background: #fff;
}

div.light_rounded .pp_bottom .pp_right {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/sprite.png) -110px -80px no-repeat;
}

div.light_rounded .pp_loaderIcon {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/loader.gif) center center no-repeat;
}

div.dark_rounded .pp_top .pp_left {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -88px -53px no-repeat;
}

div.dark_rounded .pp_top .pp_middle {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/contentPattern.png) top left repeat;
}

div.dark_rounded .pp_top .pp_right {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -110px -53px no-repeat;
}

div.dark_rounded .pp_content_container .pp_left {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/contentPattern.png) top left repeat-y;
}

div.dark_rounded .pp_content_container .pp_right {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/contentPattern.png) top right repeat-y;
}

div.dark_rounded .pp_content {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/contentPattern.png) top left repeat;
}

div.dark_rounded .pp_next:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/btnNext.png) center right no-repeat;
	cursor: pointer;
}

div.dark_rounded .pp_previous:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/btnPrevious.png) center left no-repeat;
	cursor: pointer;
}

div.dark_rounded .pp_expand {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -31px -26px no-repeat;
	cursor: pointer;
}

div.dark_rounded .pp_expand:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -31px -47px no-repeat;
	cursor: pointer;
}

div.dark_rounded .pp_contract {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) 0 -26px no-repeat;
	cursor: pointer;
}

div.dark_rounded .pp_contract:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) 0 -47px no-repeat;
	cursor: pointer;
}

div.dark_rounded .pp_close {
	width: 75px;
	height: 22px;
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -1px -1px no-repeat;
	cursor: pointer;
}

div.dark_rounded .currentTextHolder {
	color: #c4c4c4;
}

div.dark_rounded .pp_description {
	color: #fff;
}

div.dark_rounded #pp_full_res .pp_inline {
	color: #fff;
}

div.dark_rounded .pp_gallery a.pp_arrow_previous, div.dark_rounded .pp_gallery a.pp_arrow_next {
	margin-top: 12px !important;
}

div.dark_rounded .pp_nav .pp_play {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -1px -100px no-repeat;
	height: 15px;
	width: 14px;
}

div.dark_rounded .pp_nav .pp_pause {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -24px -100px no-repeat;
	height: 15px;
	width: 14px;
}

div.dark_rounded .pp_arrow_previous {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) 0 -71px no-repeat;
}

div.dark_rounded .pp_arrow_previous.disabled {
	background-position: 0 -87px;
	cursor: default;
}

div.dark_rounded .pp_arrow_next {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -22px -71px no-repeat;
}

div.dark_rounded .pp_arrow_next.disabled {
	background-position: -22px -87px;
	cursor: default;
}

div.dark_rounded .pp_bottom .pp_left {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -88px -80px no-repeat;
}

div.dark_rounded .pp_bottom .pp_middle {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/contentPattern.png) top left repeat;
}

div.dark_rounded .pp_bottom .pp_right {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/sprite.png) -110px -80px no-repeat;
}

div.dark_rounded .pp_loaderIcon {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/loader.gif) center center no-repeat;
}

div.dark_square .pp_left, div.dark_square .pp_middle, div.dark_square .pp_right, div.dark_square .pp_content {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/contentPattern.png) top left repeat;
}

div.dark_square .currentTextHolder {
	color: #c4c4c4;
}

div.dark_square .pp_description {
	color: #fff;
}

div.dark_square .pp_loaderIcon {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/loader.gif) center center no-repeat;
}

div.dark_square .pp_content_container .pp_left {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/contentPattern.png) top left repeat-y;
}

div.dark_square .pp_content_container .pp_right {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_rounded/contentPattern.png) top right repeat-y;
}

div.dark_square .pp_expand {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/sprite.png) -31px -26px no-repeat;
	cursor: pointer;
}

div.dark_square .pp_expand:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/sprite.png) -31px -47px no-repeat;
	cursor: pointer;
}

div.dark_square .pp_contract {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/sprite.png) 0 -26px no-repeat;
	cursor: pointer;
}

div.dark_square .pp_contract:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/sprite.png) 0 -47px no-repeat;
	cursor: pointer;
}

div.dark_square .pp_close {
	width: 75px;
	height: 22px;
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/sprite.png) -1px -1px no-repeat;
	cursor: pointer;
}

div.dark_square #pp_full_res .pp_inline {
	color: #fff;
}

div.dark_square .pp_gallery a.pp_arrow_previous, div.dark_square .pp_gallery a.pp_arrow_next {
	margin-top: 12px !important;
}

div.dark_square .pp_nav .pp_play {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/sprite.png) -1px -100px no-repeat;
	height: 15px;
	width: 14px;
}

div.dark_square .pp_nav .pp_pause {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/sprite.png) -24px -100px no-repeat;
	height: 15px;
	width: 14px;
}

div.dark_square .pp_arrow_previous {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/sprite.png) 0 -71px no-repeat;
}

div.dark_square .pp_arrow_previous.disabled {
	background-position: 0 -87px;
	cursor: default;
}

div.dark_square .pp_arrow_next {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/sprite.png) -22px -71px no-repeat;
}

div.dark_square .pp_arrow_next.disabled {
	background-position: -22px -87px;
	cursor: default;
}

div.dark_square .pp_next:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/btnNext.png) center right no-repeat;
	cursor: pointer;
}

div.dark_square .pp_previous:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/dark_square/btnPrevious.png) center left no-repeat;
	cursor: pointer;
}

div.light_square .pp_left, div.light_square .pp_middle, div.light_square .pp_right, div.light_square .pp_content {
	background: #fff;
}

div.light_square .pp_content .ppt {
	color: #000;
}

div.light_square .pp_expand {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/sprite.png) -31px -26px no-repeat;
	cursor: pointer;
}

div.light_square .pp_expand:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/sprite.png) -31px -47px no-repeat;
	cursor: pointer;
}

div.light_square .pp_contract {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/sprite.png) 0 -26px no-repeat;
	cursor: pointer;
}

div.light_square .pp_contract:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/sprite.png) 0 -47px no-repeat;
	cursor: pointer;
}

div.light_square .pp_close {
	width: 75px;
	height: 22px;
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/sprite.png) -1px -1px no-repeat;
	cursor: pointer;
}

div.light_square #pp_full_res .pp_inline {
	color: #000;
}

div.light_square .pp_gallery a.pp_arrow_previous, div.light_square .pp_gallery a.pp_arrow_next {
	margin-top: 12px !important;
}

div.light_square .pp_nav .pp_play {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/sprite.png) -1px -100px no-repeat;
	height: 15px;
	width: 14px;
}

div.light_square .pp_nav .pp_pause {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/sprite.png) -24px -100px no-repeat;
	height: 15px;
	width: 14px;
}

div.light_square .pp_arrow_previous {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/sprite.png) 0 -71px no-repeat;
}

div.light_square .pp_arrow_previous.disabled {
	background-position: 0 -87px;
	cursor: default;
}

div.light_square .pp_arrow_next {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/sprite.png) -22px -71px no-repeat;
}

div.light_square .pp_arrow_next.disabled {
	background-position: -22px -87px;
	cursor: default;
}

div.light_square .pp_next:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/btnNext.png) center right no-repeat;
	cursor: pointer;
}

div.light_square .pp_previous:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_square/btnPrevious.png) center left no-repeat;
	cursor: pointer;
}

div.facebook .pp_top .pp_left {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -88px -53px no-repeat;
}

div.facebook .pp_top .pp_middle {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/contentPatternTop.png) top left repeat-x;
}

div.facebook .pp_top .pp_right {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -110px -53px no-repeat;
}

div.facebook .pp_content .ppt {
	color: #000;
}

div.facebook .pp_content_container .pp_left {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/contentPatternLeft.png) top left repeat-y;
}

div.facebook .pp_content_container .pp_right {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/contentPatternRight.png) top right repeat-y;
}

div.facebook .pp_content {
	background: #fff;
}

div.facebook .pp_expand {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;
	cursor: pointer;
}

div.facebook .pp_expand:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;
	cursor: pointer;
}

div.facebook .pp_contract {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;
	cursor: pointer;
}

div.facebook .pp_contract:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;
	cursor: pointer;
}

div.facebook .pp_close {
	width: 22px;
	height: 22px;
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;
	cursor: pointer;
}

div.facebook #pp_full_res .pp_inline {
	color: #000;
}

div.facebook .pp_loaderIcon {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/loader.gif) center center no-repeat;
}

div.facebook .pp_arrow_previous {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;
	height: 22px;
	margin-top: 0;
	width: 22px;
}

div.facebook .pp_arrow_previous.disabled {
	background-position: 0 -96px;
	cursor: default;
}

div.facebook .pp_arrow_next {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;
	height: 22px;
	margin-top: 0;
	width: 22px;
}

div.facebook .pp_arrow_next.disabled {
	background-position: -32px -96px;
	cursor: default;
}

div.facebook .pp_nav {
	margin-top: 0;
}

div.facebook .pp_nav p {
	font-size: 15px;
	padding: 0 3px 0 4px;
}

div.facebook .pp_nav .pp_play {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;
	height: 22px;
	width: 22px;
}

div.facebook .pp_nav .pp_pause {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;
	height: 22px;
	width: 22px;
}

div.facebook .pp_next:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/btnNext.png) center right no-repeat;
	cursor: pointer;
}

div.facebook .pp_previous:hover {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/btnPrevious.png) center left no-repeat;
	cursor: pointer;
}

div.facebook .pp_bottom .pp_left {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -88px -80px no-repeat;
}

div.facebook .pp_bottom .pp_middle {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/contentPatternBottom.png) top left repeat-x;
}

div.facebook .pp_bottom .pp_right {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/sprite.png) -110px -80px no-repeat;
}

div.pp_pic_holder a:focus {
	outline: none;
}

div.pp_overlay {
	background: #000;
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9500;
}

div.pp_pic_holder {
	display: none;
	position: absolute;
	width: 100px;
	z-index: 10000;
}

.pp_top {
	height: 20px;
	position: relative;
}

* html .pp_top {
	padding: 0 20px;
}

.pp_top .pp_left {
	height: 20px;
	left: 0;
	position: absolute;
	width: 20px;
}

.pp_top .pp_middle {
	height: 20px;
	left: 20px;
	position: absolute;
	right: 20px;
}

* html .pp_top .pp_middle {
	left: 0;
	position: static;
}

.pp_top .pp_right {
	height: 20px;
	left: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
}

.pp_content {
	height: 40px;
}

.pp_fade {
	display: none;
}

.pp_content_container {
	position: relative;
	text-align: left;
	width: 100%;
}

.pp_content_container .pp_left {
	padding-left: 20px;
}

.pp_content_container .pp_right {
	padding-right: 20px;
}

.pp_content_container .pp_details {
	float: left;
	margin: 0px 0 2px 0;
}

.pp_description {
	display: none;
	margin: -3px 0 5px 0;
}

.pp_nav {
	clear: left;
	float: left;
	margin: 3px 0 0 0;
}

.pp_nav p {
	float: left;
	margin: 2px 4px;
}

.pp_nav .pp_play, .pp_nav .pp_pause {
	float: left;
	margin-right: 4px;
	text-indent: -10000px;
}

a.pp_arrow_previous, a.pp_arrow_next {
	display: block;
	float: left;
	height: 15px;
	margin-top: 3px;
	overflow: hidden;
	text-indent: -10000px;
	width: 14px;
}

.pp_hoverContainer {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2000;
}

.pp_gallery {
	left: 50%;
	margin-top: -50px;
	position: absolute;
	z-index: 10000;
}

.pp_gallery ul {
	float: left;
	height: 35px;
	margin: 0 0 0 5px;
	overflow: hidden;
	padding: 0;
	position: relative;
}

.pp_gallery ul a {
	border: 1px #000 solid;
	border: 1px rgba(0,0,0,0.5) solid;
	display: block;
	float: left;
	height: 33px;
	overflow: hidden;
}

.pp_gallery ul a:hover, .pp_gallery li.selected a {
	border-color: #fff;
}

.pp_gallery ul a img {
	border: 0;
}

.pp_gallery li {
	display: block;
	float: left;
	margin: 0 5px 0 0;
}

.pp_gallery li.default a {
	background: url(/content/Images/prettyPhoto/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
	display: block;
	height: 33px;
	width: 50px;
}

.pp_gallery li.default a img {
	display: none;
}

.pp_gallery .pp_arrow_previous, .pp_gallery .pp_arrow_next {
	margin-top: 7px !important;
}

a.pp_next {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
	display: block;
	float: right;
	height: 100%;
	text-indent: -10000px;
	width: 49%;
}

a.pp_previous {
	background: url(/content/Images/prettyPhoto/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
	display: block;
	float: left;
	height: 100%;
	text-indent: -10000px;
	width: 49%;
}

a.pp_expand, a.pp_contract {
	cursor: pointer;
	display: none;
	height: 20px;
	position: absolute;
	right: 30px;
	text-indent: -10000px;
	top: 10px;
	width: 20px;
	z-index: 20000;
}

a.pp_close {
	display: block;
	float: right;
	line-height: 22px;
	text-indent: -10000px;
}

.pp_bottom {
	height: 20px;
	position: relative;
}

* html .pp_bottom {
	padding: 0 20px;
}

.pp_bottom .pp_left {
	height: 20px;
	left: 0;
	position: absolute;
	width: 20px;
}

.pp_bottom .pp_middle {
	height: 20px;
	left: 20px;
	position: absolute;
	right: 20px;
}

* html .pp_bottom .pp_middle {
	left: 0;
	position: static;
}

.pp_bottom .pp_right {
	height: 20px;
	left: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 20px;
}

.pp_loaderIcon {
	display: block;
	height: 24px;
	left: 50%;
	margin: -12px 0 0 -12px;
	position: absolute;
	top: 50%;
	width: 24px;
}

#pp_full_res {
	line-height: 1 !important;
}

#pp_full_res .pp_inline {
	text-align: left;
}

#pp_full_res .pp_inline p {
	margin: 0 0 15px 0;
}
	*/
div.ppt {
	color: #fff;
	display: none;
	font-size: 17px;
	margin: 0 0 5px 15px;
	z-index: 9999;
}

.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.clearfix {
	display: inline-block;
}

* html .clearfix {
	height: 1%;
}

.clearfix {
	display: block;
}

/* checkme */

#checkme {
	position: relative;
}

.pp_description {
	font: 140% Helvetica,Arial;
}

.pp_description strong {
	color: @color-secondary;
	font-size: 150%;
}

.pp_description a {
	color: @color-primary;
	font-weight: bold;
	font-size: 150%;
}

.pp_description a:hover {
	color: @color-secondary;
}

.news li,
.faq li {
	margin-left: 30px;
}

.news li .icon,
.faq li .icon {
	margin-left: -22px;
}

.textcolumn .info a {
	color: @color-primary;
	font-weight: bold;
}

.medewerkerimgmal {
	height: 66px;
	width: 91px;
	position: relative;
	display: block;
	float: left;
	margin-right: 1em;
}

.medewerkerimgmal img, .medewerkerimgmal .mal {
	height: 66px;
	width: 91px;
	position: absolute;
	left: 0;
	top: 0;
	display: block;
}

.medewerkerimgmal .mal {
	background: url("@{images_path}medewerkermal75.png") no-repeat top left;
}

.medewerkercontactcongres {
	margin-bottom: 1em;
}

.medewerkercontactcongres em {
}

.selectable .nadruk {
    display: none;
}

.congressbuttons {
    position: absolute;
    right: 0;
    top: 6px;
    display: none;
}

.nextcongres h1 {
    color: #000;
}

.selectable .nextcongres:hover .congressbuttons {
    display: block;
    float: right;
    padding-right: 10px;
}

.selectable .nextcongres a {
    display: block;
    clear: right;

    &, p, h3 {
        color: #000;
    }

    &:hover {
        p {
            color: @color-secondary;
        }

        h3 {
            color: @color-primary;
        }
    }
}

.selectable .nextcongres .congressbuttons a {
    background: @color-secondary;
    color: #fff;
    text-transform: uppercase;
    border: 1px solid @color-secondary;
    font-family: Arial;
    font-size: 10px;
    width: 80px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    line-height: 150%;
}

.selectable .nextcongres .congressbuttons a:hover {
    background: @color-primary;
    border: 1px solid @color-primary;
}

iframe, object {
    max-width: 100%;
}

.speaker-rating-container {
    position: relative;
    margin: 6px 0;
}

.speaker-rating-container-inner {
    float: left;
    height: 15px;
    padding-top: 5px;
}

.speaker-result {
    width: 100px;
    height: 10px;
    background: url("/Content/Images/star-grey.png");
}

.speaker-result-rate {
    position: relative;
    overflow: hidden;
    top: -10px;
    height: 10px;
    background: url("/Content/Images/star-red.png");
}

.accreditation-codes {
    display: none;
}

.domainList {
    clear: both;
}

.domainList li {
    cursor: pointer;
}

.domainListLabel {
    padding-top: 40px;
}


label {
    display: inline-block;
}

.cms.cms-margins {
    p, table{
        margin-bottom:0.5em;
    }

    table thead th {
        padding-bottom:0.5em;
        color:#FC7005;
    }
    tr, td {
        vertical-align:top;
    }
    p:last-child {
        margin-bottom:0;
    }

    strong {
        color:#FC7005;
    }
}


.pager {
	padding-top: 5px;
	margin-top: 10px;
}

	.pager .disabled {
		display: none;
}

.pager a {
	padding: 10px;
	border: 1px solid #f3f4f2;
}

	.pager a:hover, .pager a:focus {
		background: #f3f4f2;
		border: 1px solid #000;
	}

.pager .current {
	padding: 10px;
	border: 1px solid #cccccc;
	background: #f3f4f2;
}

.blok-container {
	margin-left: -20px;
	margin-bottom: 20px;
}

.big-block {
	float: left;
	width: 250px;
	height: 300px;
	margin-left: 20px;
	margin-bottom: 20px;
	position: relative;
	background: #f3f4f2;
}

.big-block-img {
	position: relative;
	height: 185px;
}

	.big-block-img img {
		position: absolute;
		top: 15px;
		left: 15px;
	}

.big-block-mal {
	width: 250px;
	height: 185px;
	background: url("/Content/Images/big-block-mal.png") no-repeat top left;
	position: absolute;
	top: 0;
	left: 0;
}

.big-block h2, .big-block p {
	margin: 0;
	color: #000;
	font-family: Helvetica,Arial;
}

.big-block p {
	width: auto;
}

.big-block h2 {
	font-weight: bold;
}

.container-content {
	padding: 15px;
}

.charityInfo a {
	color: @color-primary
}

.social-media-share-inline {
	margin: 20px 0;

	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		
		.fb-share-button {
			float: left;
		}
	}
}

.mail-code-info {
	display: block;
	font-style: italic;
	font-size: 0.7rem;
	line-height: 1rem;
	padding-right: 10px;
}