﻿@import (reference) '../_Variables.less';

.counters-section {
    position: relative;
    top: -60px;
    margin-bottom: -60px;

    .counters-block {
        background-color: @color-white;
        border-radius: 10px;
        padding-top: 40px;
    }

    .counter-item {
        text-align: center;
        max-width: 175px;
        height: 125px;
        margin: 0 auto;
    }

    .counter-number {
        color: @color-beige;
    }

    @media(min-width:@breakpoint-md) {
        .counters-block {
            padding-bottom: 40px;
        }

        .counter-item {
            height: auto;
        }
    }
}