﻿.introduceOnly, .inactiveIntroduce {
    display: none;
}


tr.calculationHeader {
	th,td {
		border-top:1px solid #fff;
	}
	.currency {
		border-top:1px solid #e0e0e0;

	}
}

.non-discount {
	color: #ccc;
	text-decoration: line-through;
	-moz-text-decoration-color: #aaa;
	text-decoration-color: #aaa;
	
}