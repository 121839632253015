﻿@import (reference) '../_Variables.less';
@import (reference) '_mixins.less';

.congressblock {
	margin-top: 20px;
	background-color: @color-white;
	border-radius: 10px;

	.congressblock-content {
		padding: 15px 35px 10px 35px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: @color-darkgrey;
	}

	.congressblock-image {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		object-fit: cover;
		height: 200px;
		width: 100%;
	}

	.congressblock-inline {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
	}

	.date {
		.font-size(@font-size-small + 1px);
	}

	.congressblock-inlinecontent {
		.font-size(@font-size-small + 1px);
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: 10px;
	}

	.important-information {
		background-color: @color-beige;
		padding: 3px 8px;
		border-radius: 4px;
	}

	.next-button-xs {
		img {
			height: 40px;
			width: 50px;
		}
	}

	.next-button-sm {
		height: 160px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 60px;
			width: 70px;
		}
	}

	&.wzd {
		background-color: @color-grey3;
		height: auto;

		.cms {
			margin-top: .5em;

			ul li {
				margin-bottom: 0;
			}
		}

		@media(min-width: @breakpoint-md) {
			.congressblock, .congressblock-content {
				height: auto;
			}
		}
	}

	.label-wrap {
		position: absolute;
		left: 2rem;
		top: 10px;

		span.txt-label {
			display: inline;
			padding: 0.2em 0.6em 0.3em;
			font-size: 80%;
			border-radius: 3px;
			font-weight: bold;
			margin-right: 3px;

			&.stap {
				//border: 1px solid @color-green;
				background-color: @color-green;
				color: @color-white;
			}

			&.wzd {
				//border: 1px solid @color-beige;
				background-color: @color-beige;
				color: @color-white;
			}
		}
	}
}

@media(min-width:@breakpoint-md) {
    .congressblock {
        height: 160px;

        .congressblock-content {
            padding: 15px 15px 10px 15px;
            height: 160px;
        }

        .congressblock-inline {
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-top: 0px;
            margin: 0px -10px;
        }

        .congressblock-inlinecontent {
            margin-right: 10px;
            margin-left: 10px;
        }

        .congressblock-image {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-top-right-radius: 0px;
            height: 160px;
        }
    }

    .autoheightcongresses {
        .congressblock {
            min-height: 160px;
            height: auto;
        }

        .congressblock-content {
            height: auto;
        }

        .congressblock-image {
            height: 100%
        }
        .next-button-sm {
            height: auto;
        }
    }
}

.icon-calendar {
    background: url("Images/icons/calendar.svg");
    width: 17px;
    height: 20px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
}

.congressblocks-pdf {
    .congressblock:nth-child(7n + 1):not(:first-child) {
        page-break-before: always;
        margin-top: 50px;
    }

    .congressblock-content {
        width: 75%;
    }

    .next-button-sm {
        display: none;
    }
}